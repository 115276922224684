import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./ClientDetails.css";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { CgAdd, CgAddR, CgArrowRight, CgFormatRight } from "react-icons/cg";
import AddClientImg from "../../../images/Add Client.png";
import { TabPanel, TabView } from "primereact/tabview";
import {
  BiArrowFromRight,
  BiLogInCircle,
  BiSolidCalendarEdit,
  BiUserCircle,
} from "react-icons/bi";
import { Dropdown } from "primereact/dropdown";
import { TiUserAdd } from "react-icons/ti";
import { AutoComplete } from "primereact/autocomplete";
import { Password } from "primereact/password";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Chip } from "primereact/chip";

function ManageClients() {
  const toast = useRef();
  const stepperRef = useRef(null);
  const [allClientDetails, setAllClientDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [viewClientsTbl, setViewClientsTbl] = useState(true);
  const [viewClntLoginTbl, setViewClntLoginTbl] = useState(false);
  const [isLoadingRole, setIsLoadingRole] = useState(false);
  const [allLogins, setAllLogins] = useState([]);
  const [allIncidentOwnrs, setAllIncidentOwnrs] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [allClientLogins, setAllClientLogins] = useState([]);
  const [allLoginsExcptClient, setAllLoginsExcptClient] = useState([]);
  const [viewUsers, setViewUsers] = useState(true);
  const [viewLogins, setViewLogins] = useState(false);
  const [viewRoles, setViewRoles] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [createLogin, setCreateLogin] = useState(false);
  const [updatePwd, setUpdatePwd] = useState(false);
  const [createRole, setCreateRole] = useState(false);
  const [uniqID, setUniqID] = useState("");
  const [confirmPwdFocused, setConfirmPwdFocused] = useState(false);
  const [pwdError, setPwdError] = useState("");
  const [userNme, setUserNme] = useState("");
  const [userPwdOne, setUserPwdOne] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [formClientDetails, setFormClientDetails] = useState({
    clientname: "",
    clientEmailid: "",
    clientaddress: "",
    clientcontactNumber: "",
  });
  const [formDataLogin, setFormDataLogin] = useState({
    uniqueID: "",
    userName: "",
    userPassword: "",
    confirmPwd: "",
    roleName: "",
  });
  const [formDataRole, setFormDataRole] = useState({
    roleName: "",
    roleDescription: "",
  });
  const [usersAndEmail, setUsersAndEmail] = useState({
    displayName: [],
    selectName: [],
  });
  const [clientsAndEmail, setClientsAndEmail] = useState({
    displayName: [],
    selectName: [],
  });

  const [dataListRoles, setDataListRoles] = useState({
    idsRole: [],
    namesRole: [],
  });
  const [openDialog, setOpenDialog] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [error, setError] = useState("");

  async function fetchClientDetails() {
    const response = await fetch("/allClientDetails", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllClientDetails(result);
    } else {
      setAllClientDetails([]);
    }
  }

  async function fetchAllLogins() {
    const response = await fetch("/allUsersByRole", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllLogins(result);
    } else {
      setAllLogins([]);
    }
  }

  async function fetchUserEmails() {
    const response = await fetch("/allUsersWithEmailID", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    setUsersAndEmail(result);
  }

  async function fetchClientsEmails() {
    const response = await fetch("/allClientsWithEmailID", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    setClientsAndEmail(result);
  }
  async function fetchAllClientLogins() {
    const response = await fetch("/allClientLogins", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllClientLogins(result);
    } else {
      setAllClientLogins([]);
    }
  }
  async function fetchAllLoginsExcptClient() {
    const response = await fetch("/usersExeptClntLoginsAndRoles", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllLoginsExcptClient(result);
    } else {
      setAllLoginsExcptClient([]);
    }
  }
  async function fetchAllIncidentUsers() {
    const response = await fetch("/allIncidentOwners", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllIncidentOwnrs(result);
    } else {
      setAllIncidentOwnrs([]);
    }
  }

  async function fetchAllRoles() {
    const response = await fetch("/allRoles", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllRoles(result);
    } else {
      setAllRoles([]);
    }
  }

  useEffect(() => {
    async function fetchAllRolesList() {
      try {
        const response = await fetch("/allRolesList", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        });
        const result = await response.json();
        if (result.ids && result.names) {
          setDataListRoles({
            idsRole: result.ids,
            namesRole: result.names,
          });
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }

    fetchClientDetails();
    fetchAllLogins();
    fetchAllRoles();
    fetchAllRolesList();
    fetchAllIncidentUsers();
    fetchAllClientLogins();
    fetchAllLoginsExcptClient();
    fetchUserEmails();
    fetchClientsEmails();
  }, []);

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        keyfilter={/^[a-zA-Z ]*$/}
        value={options.value}
        onChange={(e) =>
          options.editorCallback(e.target.value.substring(0, 150))
        }
      />
    );
  };

  const textEditorRole = (options) => {
    return (
      <InputText
        type="text"
        keyfilter={/^[a-zA-Z ]*$/}
        value={options.value}
        onChange={(e) =>
          options.editorCallback(e.target.value.substring(0, 150))
        }
      />
    );
  };
  const textEditorNormal = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) =>
          options.editorCallback(e.target.value.substring(0, 50))
        }
      />
    );
  };

  const textEditorContactNo = (options) => {
    const handleChange = (e) => {
      let newValue = e.target.value;
      newValue = "+" + newValue.slice(1).replace(/[^0-9]/g, "");
      options.editorCallback(newValue.substring(0, 150));
    };

    return (
      <InputText
        type="text"
        value={options.value}
        onChange={handleChange}
        maxLength={15}
      />
    );
  };

  const textEditorEmail = (options) => {
    const EmailEditChange = (e) => {
      const value = e.target.value;

      if (!emailRegex.test(value)) {
        setError("Invalid EmailID");
      } else {
        setError("");
      }
      options.editorCallback(value.substring(0, 150));
    };

    return (
      <>
        <InputText
          type="email"
          value={options.value}
          onChange={EmailEditChange}
          required
        />
        {error && (
          <p
            style={{ color: "red", fontSize: "10px" }}
            className="remaningChars">
            {error}
          </p>
        )}
      </>
    );
  };

  const onRowEditRolesComplete = async (e) => {
    let _tasks = [...allRoles];
    let { newData, index } = e;

    _tasks[index] = newData;

    if (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Incorrect EmailID",
        life: 3000,
      });

      return;
    }

    setAllRoles(_tasks);

    try {
      const response = await fetch("/bulkUpdateRoles", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([newData]),
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Updated successfully!",
          life: 3000,
        });
        // triggerApiDataChange();
        fetchAllRoles();
        setError("");
      }

      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update data!",
          life: 3000,
        });
        // triggerApiDataChange();
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const onRowEditComplete = async (e) => {
    let _tasks = [...allClientDetails];
    let { newData, index } = e;

    _tasks[index] = newData;

    if (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Incorrect EmailID",
        life: 3000,
      });

      return;
    }

    setAllClientDetails(_tasks);

    try {
      const response = await fetch("/bulkUpdateClientDetails", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([newData]),
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Client Details Updated successfully!",
          life: 3000,
        });
        // triggerApiDataChange();
        fetchClientDetails();
        setError("");
      }

      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update data!",
          life: 3000,
        });
        // triggerApiDataChange();
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const onRowEditUsers = async (e) => {
    let _tasks = [...allClientDetails];
    let { newData, index } = e;

    _tasks[index] = newData;

    if (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Incorrect EmailID",
        life: 3000,
      });

      return;
    }

    setAllIncidentOwnrs(_tasks);

    try {
      const response = await fetch("/bulkUpdateIncidentOwners", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([newData]),
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Updated successfully!",
          life: 3000,
        });
        // triggerApiDataChange();
        fetchAllIncidentUsers();
        setError("");
      }

      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update data!",
          life: 3000,
        });
        // triggerApiDataChange();
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleInputChange = (name, value) => {
    setFormClientDetails((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormDataLogin((prevFormLogin) => ({
      ...prevFormLogin,
      [name]: value,
    }));
    setFormDataRole((prevFormRole) => ({
      ...prevFormRole,
      [name]: value,
    }));
  };
  const handlePasswordChange = () => {
    // Compare passwords live
    if (userPwd !== userPwdOne) {
      setPwdError("Passwords do not match");
    } else {
      setPwdError(""); // Clear error message if passwords match
    }
  };

  useEffect(() => {
    if (confirmPwdFocused && userPwd !== userPwdOne) {
      setPwdError("Passwords do not match");
    } else {
      setPwdError("");
    }
  }, [userPwd, userPwdOne]);
  const handleInputChangeNo = (name, value) => {
    let newValue = value;

    newValue = "+" + newValue.slice(1).replace(/[^0-9]/g, "");

    setFormClientDetails((prevFormData) => ({
      ...prevFormData,
      [name]: newValue.substring(0, 15),
    }));
  };

  const crearVariales = () => {
    setFormClientDetails({
      clientname: "",
      clientaddress: "",
      clientEmailid: "",
      clientContactNumber: "",
    });

    setFormDataRole({
      roleName: "",
      roleDescription: "",
    });

    setFormDataLogin({
      uniqueID: "",
      userName: "",
      userPassword: "",
      roleName: "",
    });

    setUserNme("");
    setUserPwd("");
    setUniqID("");
  };

  const handleRowClick = (rowData) => {
    setUniqID(rowData.uniqueID);
    setUserNme(rowData.userName);
    setUpdatePwd(true);
  };

  const rowClicked = (rowData) => {
    return (
      <Button
        icon={<BiSolidCalendarEdit />}
        text
        title="edit"
        onClick={() => handleRowClick(rowData)}
        // className="roundad"
      />
    );
  };

  const handleSubmitAddClients = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Proceed with adding the holiday
      const response = await fetch("/InsertBulkClientDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            clientName: formClientDetails.clientname,
            clientEmailID: formClientDetails.clientEmailid,
            clientAddress: formClientDetails.clientaddress,
            clientContactNumber: formClientDetails.clientcontactNumber,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Client Added successfully!",
          life: 3000,
        });
        crearVariales();
        setOpenDialog(false);
        fetchClientDetails();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating Client.",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating Holiday.",
        life: 3000,
      });
    }

    setIsLoading(false);
  };

  const handleSubmitNewLogin = async (e) => {
    e.preventDefault();
    setIsLoadingLogin(true);

    try {
      // Proceed with adding the holiday
      const response = await fetch("/registerNewUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify({
          uniqueID: 5,
          userName: formDataLogin.userName,
          userPassword: formDataLogin.userPassword,
          roleName: formDataLogin.roleName,
        }),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved successfully!",
          life: 3000,
        });
        crearVariales();
        setOpenDialog(false);
        setCreateLogin(false);
        setCreateRole(false);
        fetchAllLogins();
        fetchAllClientLogins();
        fetchAllLoginsExcptClient();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error Occured While saving.",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Some Error Occured",
        life: 3000,
      });
    }

    setIsLoadingLogin(false);
  };
  const handleSubmitNewRole = async (e) => {
    e.preventDefault();
    setIsLoadingRole(true);

    try {
      const response = await fetch("/createNewRole", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify({
          roleName: formDataRole.roleName,
          roleDescription: formDataRole.roleDescription,
        }),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved Successfully!",
          life: 3000,
        });
        crearVariales();
        setOpenDialog(false);
        setCreateLogin(false);
        setCreateRole(false);
        fetchAllRoles();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error Occured While Saving",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Some Error Occured",
        life: 3000,
      });
    }

    setIsLoadingRole(false);
  };

  const handleSubmitAddUser = async (e) => {
    e.preventDefault();
    setIsLoadingRole(true);

    try {
      const response = await fetch("/InsertBulkIncidentOwners", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            fullName: formDataRole.roleName,
            emailID: formDataRole.roleDescription,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved Successfully!",
          life: 3000,
        });
        crearVariales();
        setOpenDialog(false);
        setCreateLogin(false);
        setCreateRole(false);
        fetchAllRoles();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error Occured While Saving",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Some Error Occured",
        life: 3000,
      });
    }

    setIsLoadingRole(false);
  };

  const handleSubmitUpdatePwd = async (e) => {
    e.preventDefault();
    setIsLoadingRole(true);

    try {
      const response = await fetch("/updatePasswords", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify({
          uniqueID: uniqID,
          userPassword: userPwd,
        }),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved Successfully!",
          life: 3000,
        });
        crearVariales();
        setOpenDialog(false);
        setCreateLogin(false);
        setCreateRole(false);
        setUpdatePwd(false);
        fetchAllLogins();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error Occured While Saving",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Some Error Occured",
        life: 3000,
      });
    }

    setIsLoadingRole(false);
  };

  const createNewLogin = () => {
    setCreateLogin(true);
    setCreateRole(false);
  };
  const createNewRoles = () => {
    setCreateLogin(false);
    setCreateRole(true);
  };

  const cities = [
    {
      name: "User/Login/Role",
      code: "IO",
      onclick: createNewLogin,
      icon: <TiUserAdd />,
    },
  ];

  const dropDownIcons = (option) => {
    return (
      <div className="flex align-items-center">
        <div>
          {option.icon} {option.name}
        </div>
      </div>
    );
  };

  const placeholderTemplate = () => {
    return (
      <div className="p-clearfix" style={{ color: "white" }}>
        <CgAddR style={{ marginRight: "5px", fontSize: "1.2em" }} />
        <span style={{ fontSize: "1em", fontWeight: "bold", color: "white" }}>
          Create
        </span>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      {/*Add Client Dialog */}
      <Dialog
        visible={openDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setOpenDialog(false);
          crearVariales();
        }}
        className="dialogAddForms">
        <Stepper ref={stepperRef} style={{ flexBasis: "50rem" }}>
          <StepperPanel header="Add Client">
            <div className="grid">
              <div className="col-12 lg:col-6">
                <div className="addClientLeftImg">
                  <img src={AddClientImg} alt="" width="100%" />
                </div>
              </div>
              <div className="col-12 lg:col-6">
                {" "}
                <form onSubmit={handleSubmitAddClients}>
                  <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="Holiday Name" className="font-bold block">
                      Client Name
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <InputText
                      aria-describedby="username-help"
                      value={formClientDetails.clientname}
                      keyfilter={/^[a-zA-Z\s]+$/}
                      onChange={(e) => {
                        handleInputChange(
                          "clientname",
                          e.target.value.substring(0, 25)
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-wrap gap-3 p-fluid">
                    <label htmlFor="buttondisplay" className="font-bold block">
                      Client EmailID
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>

                    <InputText
                      aria-describedby="username-help"
                      value={formClientDetails.clientEmailid}
                      type="email"
                      // className="datemobileFile"
                      onChange={(e) => {
                        handleInputChange(
                          "clientEmailid",
                          e.target.value.substring(0, 150)
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="Day" className="font-bold block">
                      Client Address{" "}
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <InputText
                      aria-describedby="username-help"
                      value={formClientDetails.clientaddress}
                      onChange={(e) => {
                        handleInputChange(
                          "clientaddress",
                          e.target.value.substring(0, 150)
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="Day" className="font-bold block">
                      Contact Number
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <InputText
                      aria-describedby="username-help"
                      value={formClientDetails.clientcontactNumber}
                      onChange={(e) => {
                        handleInputChangeNo(
                          "clientcontactNumber",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                  <div className="flex justify-content-center mt-3">
                    <Button
                      type="submit"
                      label="Submit"
                      loading={isLoading}
                      disabled={
                        !formClientDetails.clientname ||
                        !formClientDetails.clientEmailid ||
                        !formClientDetails.clientaddress ||
                        !formClientDetails.clientcontactNumber
                      }
                      rounded
                      raised
                      className="w-full roundad"
                    />
                  </div>
                </form>
                <div className="flex pt-4 justify-content-end">
                  <Button
                    label="Next"
                    icon={<CgArrowRight />}
                    iconPos="right"
                    rounded
                    severity="success"
                    onClick={() => stepperRef.current.nextCallback()}
                  />
                </div>
              </div>
            </div>
          </StepperPanel>
          <StepperPanel header="Create Login">
            <div className="grid">
              <div className="col-12  lg:col-6">
                <div className="addClientLeftImg">
                  <img src={AddClientImg} alt="" width="100%" />
                </div>
              </div>
              <div className="col-12 lg:col-6">
                <form onSubmit={handleSubmitNewLogin}>
                  <div className="flex flex-column gap-2 mb-2">
                    <label htmlFor="Holiday Name" className="font-bold block">
                      User Name
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <Dropdown
                      value={formDataLogin.userName}
                      options={clientsAndEmail.displayName.map(
                        (name, index) => ({
                          label: name,
                          value: clientsAndEmail.selectName[index],
                        })
                      )}
                      onChange={(e) => {
                        setFormDataLogin({
                          ...formDataLogin,
                          userName: e.value,
                        });
                      }}
                      placeholder="Select Client"
                      className="w-full"
                      checkmark={true}
                      highlightOnSelect={true}
                    />
                    <p>{formDataLogin.userName}</p>
                  </div>
                  <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="Day" className="font-bold block">
                      Role
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <Dropdown
                      value={formDataLogin.roleName}
                      onChange={(e) => {
                        setFormDataLogin({
                          ...formDataLogin,
                          roleName: e.value,
                        });
                      }}
                      options={dataListRoles.namesRole.map((name, index) => ({
                        label: name,
                        value: dataListRoles.namesRole[index],
                      }))}
                      placeholder="Select Role"
                      className="w-full"
                      filter
                      checkmark={true}
                      highlightOnSelect={true}
                    />
                  </div>
                  <div className="flex flex-wrap gap-3 p-fluid">
                    <label htmlFor="buttondisplay" className="font-bold block">
                      Enter Password
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <Password
                      value={formDataLogin.userPassword}
                      onChange={(e) => {
                        handleInputChange(
                          "userPassword",
                          e.target.value.substring(0, 150)
                        );
                      }}
                      toggleMask
                      required
                      className="customInputField"
                      feedback={true}
                    />
                  </div>
                  <div className="flex flex-wrap gap-3 p-fluid">
                    <label htmlFor="buttondisplay" className="font-bold block">
                      Confirm Password
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <Password
                      value={formDataLogin.confirmPwd}
                      onChange={(e) => {
                        handleInputChange(
                          "confirmPwd",
                          e.target.value.substring(0, 150)
                        );
                      }}
                      toggleMask
                      required
                      className="customInputField"
                      feedback={true}
                    />
                  </div>
                  {formDataLogin.userPassword !== formDataLogin.confirmPwd && (
                    <p style={{ color: "red", fontSize: "small" }}>
                      Password Not Matches
                    </p>
                  )}
                  <div className="flex justify-content-center mt-3">
                    <Button
                      type="submit"
                      label="Submit"
                      loading={isLoadingLogin}
                      disabled={
                        !formDataLogin.userName ||
                        !formDataLogin.confirmPwd ||
                        !formDataLogin.roleName ||
                        formDataLogin.userPassword !== formDataLogin.confirmPwd
                      }
                      rounded
                      raised
                      className="w-full roundad"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
            </div>
          </StepperPanel>
        </Stepper>
      </Dialog>
      {/*Create Login Dialog */}
      <Dialog
        visible={createLogin}
        style={{ width: "50vw" }}
        onHide={() => {
          setCreateLogin(false);
          setCreateRole(false);
          crearVariales();
        }}
        className="dialogAddForms">
        <Stepper ref={stepperRef} style={{ flexBasis: "50rem" }}>
          <StepperPanel header="Add Role">
            <div className="grid">
              <div className="col-12  lg:col-6">
                <div className="addClientLeftImg">
                  <img src={AddClientImg} alt="" width="100%" />
                </div>
              </div>
              <div className="col-12 lg:col-6">
                <form onSubmit={handleSubmitNewRole}>
                  <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="Holiday Name" className="font-bold block">
                      Role Name
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <InputText
                      aria-describedby="username-help"
                      value={formDataRole.roleName}
                      keyfilter={/^[a-zA-Z\s]+$/}
                      onChange={(e) => {
                        handleInputChange(
                          "roleName",
                          e.target.value.substring(0, 25)
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-wrap gap-3 p-fluid">
                    <label htmlFor="buttondisplay" className="font-bold block">
                      Description
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>

                    <InputText
                      aria-describedby="username-help"
                      value={formDataRole.roleDescription}
                      // className="datemobileFile"
                      onChange={(e) => {
                        handleInputChange(
                          "roleDescription",
                          e.target.value.substring(0, 150)
                        );
                      }}
                    />
                  </div>

                  <div className="flex justify-content-center mt-3">
                    <Button
                      type="submit"
                      label="Submit"
                      loading={isLoadingRole}
                      disabled={
                        !formDataRole.roleName || !formDataRole.roleDescription
                      }
                      rounded
                      raised
                      className="w-full roundad"
                    />
                  </div>
                </form>

                <div className="flex pt-4 justify-content-end">
                  <Button
                    label="Next"
                    rounded
                    icon="pi pi-arrow-left"
                    onClick={() => stepperRef.current.nextCallback()}
                  />
                </div>
              </div>
            </div>
          </StepperPanel>
          <StepperPanel header="Add User">
            <div className="grid">
              <div className="col-12  lg:col-6">
                <div className="addClientLeftImg">
                  <img src={AddClientImg} alt="" width="100%" />
                </div>
              </div>
              <div className="col-12 lg:col-6">
                <form onSubmit={handleSubmitAddUser}>
                  <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="Holiday Name" className="font-bold block">
                      Full Name
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <InputText
                      aria-describedby="username-help"
                      value={formDataRole.roleName}
                      keyfilter={/^[a-zA-Z\s]+$/}
                      onChange={(e) => {
                        handleInputChange(
                          "roleName",
                          e.target.value.substring(0, 25)
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-wrap gap-3 p-fluid">
                    <label htmlFor="buttondisplay" className="font-bold block">
                      EmailID
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>

                    <InputText
                      aria-describedby="username-help"
                      type="email"
                      keyfilter="email"
                      value={formDataRole.roleDescription}
                      // className="datemobileFile"
                      onChange={(e) => {
                        handleInputChange(
                          "roleDescription",
                          e.target.value.substring(0, 150)
                        );
                      }}
                    />
                  </div>

                  <div className="flex justify-content-center mt-3">
                    <Button
                      type="submit"
                      label="Submit"
                      loading={isLoadingRole}
                      disabled={
                        !formDataRole.roleName || !formDataRole.roleDescription
                      }
                      rounded
                      raised
                      className="w-full roundad"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="flex justify-content-between pt-4">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                rounded
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-left"
                rounded
                onClick={() => stepperRef.current.nextCallback()}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Create Login">
            <div className="grid">
              <div className="col-12  lg:col-6">
                <div className="addClientLeftImg">
                  <img src={AddClientImg} alt="" width="100%" />
                </div>
              </div>
              <div className="col-12 lg:col-6">
                <form onSubmit={handleSubmitNewLogin}>
                  <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="Holiday Name" className="font-bold block">
                      User Name
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <Dropdown
                      value={formDataLogin.userName}
                      options={usersAndEmail.displayName.map((name, index) => ({
                        label: name,
                        value: usersAndEmail.selectName[index],
                      }))}
                      onChange={(e) => {
                        setFormDataLogin({
                          ...formDataLogin,
                          userName: e.value,
                        });
                      }}
                      placeholder="Select User"
                      className="w-full"
                      checkmark={true}
                      highlightOnSelect={true}
                    />
                    <p>{formDataLogin.userName}</p>
                  </div>
                  <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="Day" className="font-bold block">
                      Role
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <Dropdown
                      value={formDataLogin.roleName}
                      onChange={(e) => {
                        setFormDataLogin({
                          ...formDataLogin,
                          roleName: e.value,
                        });
                      }}
                      options={dataListRoles.namesRole.map((name, index) => ({
                        label: name,
                        value: dataListRoles.namesRole[index],
                      }))}
                      placeholder="Select Role"
                      className="w-full"
                      filter
                      checkmark={true}
                      highlightOnSelect={true}
                    />
                  </div>
                  <div className="flex flex-wrap gap-3 p-fluid">
                    <label htmlFor="buttondisplay" className="font-bold block">
                      Enter Password
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <Password
                      value={formDataLogin.userPassword}
                      onChange={(e) => {
                        handleInputChange(
                          "userPassword",
                          e.target.value.substring(0, 150)
                        );
                      }}
                      toggleMask
                      required
                      className="customInputField"
                      feedback={true}
                    />
                  </div>
                  <div className="flex flex-wrap gap-3 p-fluid">
                    <label htmlFor="buttondisplay" className="font-bold block">
                      Confirm Password
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <Password
                      value={formDataLogin.confirmPwd}
                      onChange={(e) => {
                        handleInputChange(
                          "confirmPwd",
                          e.target.value.substring(0, 150)
                        );
                      }}
                      toggleMask
                      required
                      className="customInputField"
                      feedback={true}
                    />
                  </div>
                  {formDataLogin.userPassword !== formDataLogin.confirmPwd && (
                    <p style={{ color: "red", fontSize: "small" }}>
                      Password Not Matches
                    </p>
                  )}
                  <div className="flex justify-content-center mt-3">
                    <Button
                      type="submit"
                      label="Submit"
                      loading={isLoadingLogin}
                      disabled={
                        !formDataLogin.userName ||
                        !formDataLogin.confirmPwd ||
                        !formDataLogin.roleName ||
                        formDataLogin.userPassword !== formDataLogin.confirmPwd
                      }
                      rounded
                      raised
                      className="w-full roundad"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="flex justify-content-start pt-4">
              <Button
                label="Back"
                rounded
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
            </div>
          </StepperPanel>
        </Stepper>
      </Dialog>
      {/*Create Role Dialog */}

      {/*Update Password Dialog */}
      <Dialog
        visible={updatePwd}
        style={{ width: "50vw" }}
        onHide={() => {
          setCreateLogin(false);
          setCreateRole(false);
          setUpdatePwd(false);
          crearVariales();
        }}
        className="dialogAddForms">
        <Card className="shadow-5 AddHolidayyform">
          <h3 className="text-center">Update Password</h3>
          <div className="grid">
            <div className="col-12  lg:col-6">
              <div className="addClientLeftImg">
                <img src={AddClientImg} alt="" width="100%" />
              </div>
            </div>
            <div className="col-12 lg:col-6">
              <form onSubmit={handleSubmitUpdatePwd}>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Holiday Name" className="font-bold block">
                    UserName
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={userNme}
                    keyfilter={/^[a-zA-Z\s]+$/}
                    disabled
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    New Password
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <Password
                    value={userPwdOne}
                    onChange={(e) => setUserPwdOne(e.target.value)}
                    toggleMask
                  />
                </div>{" "}
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    Confirm Password
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <Password
                    value={userPwd}
                    onChange={(e) => {
                      setUserPwd(e.target.value);
                    }}
                    onFocus={() => setConfirmPwdFocused(true)}
                    onBlur={() => setConfirmPwdFocused(false)}
                    toggleMask
                  />
                </div>
                {pwdError && (
                  <div className="text-red-500 mt-2">{pwdError}</div>
                )}
                <div className="flex justify-content-center mt-3">
                  <Button
                    type="submit"
                    label="Submit"
                    loading={isLoadingRole}
                    disabled={!userNme || !userPwd}
                    rounded
                    raised
                    className="w-full roundad"
                  />
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Dialog>
      <Card className="m-4">
        <TabView>
          <TabPanel header="Clients">
            <div className="flex justify-content-between">
              <div>
                <Button
                  label="Clients"
                  icon={<BiUserCircle />}
                  onClick={() => {
                    setViewClientsTbl(true);
                    setViewClntLoginTbl(false);
                  }}
                  rounded
                  severity="secondary"
                  size="small"
                  style={{
                    backgroundColor: "#e5e7eb",
                    color: "#5a6370",
                  }}></Button>
                <Button
                  label="Logins"
                  icon={<BiLogInCircle />}
                  onClick={() => {
                    setViewClientsTbl(false);
                    setViewClntLoginTbl(true);
                  }}
                  className="ml-2 font-medium"
                  rounded
                  severity="secondary"
                  size="small"
                  style={{
                    backgroundColor: "#e5e7eb",
                    color: "#5a6370",
                  }}
                />
              </div>

              <Button
                raised
                rounded
                style={{ backgroundColor: "#0055cc" }}
                onClick={() => {
                  setOpenDialog(true);
                }}>
                Add <CgAdd className="ml-2" />
              </Button>
            </div>
            <Divider />
            {viewClientsTbl && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allClientDetails}
                size="small"
                paginator
                editMode="row"
                dataKey="clientID"
                onRowEditComplete={onRowEditComplete}
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column
                  header="ClientID"
                  field="clientID"
                  frozen
                  filter
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="ClientName"
                  field="clientName"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditor(options)}></Column>
                <Column
                  header="ClientEmailID"
                  field="clientEmailID"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditorEmail(options)}></Column>
                <Column
                  header="ClientAddress"
                  field="clientAddress"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditorNormal(options)}></Column>
                <Column
                  header="ClientContactNumber"
                  field="clientContactNumber"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditorContactNo(options)}></Column>
                <Column rowEditor></Column>
              </DataTable>
            )}
            {viewClntLoginTbl && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allClientLogins}
                size="small"
                paginator
                // editMode="row"
                // dataKey="clientID"
                // onRowEditComplete={onRowEditComplete}
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column
                  header="ClientName"
                  field="clientName"
                  frozen
                  filter
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="UserName"
                  field="userName"
                  className="allTableTDCenterCommon"
                  sortable
                  filter></Column>
                <Column
                  header="Role"
                  field="roleName"
                  className="allTableTDCenterCommon"
                  sortable
                  filter></Column>
                <Column
                  header="Edit"
                  className="allTableTDCenterCommon"
                  sortable></Column>
              </DataTable>
            )}
          </TabPanel>
          <TabPanel header="Users">
            <div className="flex justify-content-between">
              <div>
                <Chip
                  label="Users"
                  icon={<BiUserCircle />}
                  onClick={() => {
                    setViewLogins(false);
                    setViewRoles(false);
                    setViewUsers(true);
                  }}
                  className="font-medium"
                  style={{ cursor: "pointer" }}></Chip>
                <Chip
                  label="Roles"
                  icon={<BiLogInCircle />}
                  onClick={() => {
                    setViewLogins(false);
                    setViewRoles(true);
                    setViewUsers(false);
                  }}
                  className="ml-2 font-medium"
                  style={{ cursor: "pointer" }}
                />
                <Chip
                  label="Logins"
                  icon={<BiLogInCircle />}
                  onClick={() => {
                    setViewLogins(true);
                    setViewRoles(false);
                    setViewUsers(false);
                  }}
                  className="ml-2 font-medium"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div>
                <Dropdown
                  value={selectedOption}
                  onChange={(e) => {
                    setSelectedOption(e.value);
                    if (e.value && e.value.onclick) {
                      e.value.onclick();
                    }
                  }}
                  options={cities}
                  optionLabel="name"
                  placeholder={placeholderTemplate()}
                  className="w-full shadow-3"
                  itemTemplate={dropDownIcons}
                  style={{
                    backgroundColor: "#0055cc",
                    color: "white",
                    borderRadius: "30px",
                  }}
                  rounded
                />
              </div>
            </div>
            {viewUsers && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allIncidentOwnrs}
                size="small"
                paginator
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found."
                editMode="row"
                dataKey="assignID"
                onRowEditComplete={onRowEditUsers}>
                <Column field="assignID" header="ID" sortable />
                <Column
                  field="fullName"
                  header="Name"
                  sortable
                  editor={(options) => textEditor(options)}
                />
                <Column
                  field="emailID"
                  header="EmailID"
                  sortable
                  editor={(options) => textEditorEmail(options)}
                />
                <Column rowEditor></Column>
              </DataTable>
            )}
            {viewLogins && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allLoginsExcptClient}
                size="small"
                paginator
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column field="empUsersID" header="ID" sortable />
                <Column field="fullName" header="FullName" sortable />
                <Column field="userName" header="UserName" sortable />
                <Column field="roleName" header="Role" sortable />
                <Column header="Edit" body={rowClicked}></Column>
              </DataTable>
            )}
            {viewRoles && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allRoles}
                size="small"
                paginator
                editMode="row"
                dataKey="roleId"
                onRowEditComplete={onRowEditRolesComplete}
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column field="roleId" header="ID" />
                <Column
                  field="roleName"
                  header="Role"
                  sortable
                  editor={(options) => textEditorRole(options)}></Column>
                <Column
                  field="roleDescription"
                  header="Description"
                  editor={(options) => textEditorRole(options)}
                />
                <Column rowEditor></Column>
                <Column />
              </DataTable>
            )}
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}

export default ManageClients;
