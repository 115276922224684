import React, { useEffect, useState, useRef } from "react";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "./IncidentsCssFile.css";
import { ToggleButton } from "primereact/togglebutton";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { TabPanel, TabView } from "primereact/tabview";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import AddClientImg from "../../../images/Add Client.png";
import { BiFile } from "react-icons/bi";

function MonitorIncidents() {
  const toast = useRef();
  const [openTktDialog, setOpenTktDialog] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [caseIDForAPI, setCaseIDForAPI] = useState(null);
  const [fullName, setFullName] = useState("");

  const [dataListClients, setDataListClients] = useState({
    idsOfClients: [],
    namesOfclients: [],
  });
  const [dataListUsers, setDataListUsers] = useState({
    idsUsers: [],
    namesUsers: [],
  });
  const [dataListCategory, setDataListCategory] = useState({
    idsCategory: [],
    namesCategory: [],
  });
  const role = sessionStorage.getItem("roleName");
  const [allClientIncidents, setAllClientIncidents] = useState([]);
  const [incidentsByCaseID, setIncidentsByCaseID] = useState([]);
  const [incidentsAndFiles, setIncidentsAndFiles] = useState([]);
  const [incidentOwners, setIncidentOwners] = useState([]);
  const [viewTktComments, setViewTktComments] = useState(false);
  const [addIncident, setAddIncident] = useState(false);
  const [addIncidentOwners, setAddIncidentOwners] = useState(false);
  const [formIncidents, setFormIncidents] = useState({
    apiID: "",
    caseID: "",
    clientID: "",
    categoryID: "",
    description: "",
    status: "",
    comments: "",
    assigntedTo: "",
    caseOwner: "",
    dueDate: "",
  });
  const [formUpload, setFormUpload] = useState({
    caseID: "",
    clientID: "",
    descriptionOfUpload: "",
    comments: "",
    fileType: "",
    uploadedBy: role,
  });
  const [allFiles, setAllFiles] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const handleInputChange = (name, value) => {
    setFormIncidents((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const crearVariales = () => {
    setFormIncidents({
      apiID: "",
      clientID: "",
      categoryID: "",
      description: "",
      comments: "",
      assigntedTo: "",
      caseOwner: "",
      dueDate: "",
      status: "",
    });
  };
  async function fetchIncidentDetailsByCaseID() {
    const response = await fetch(
      `/incidentsUpdatesByCaseID/${formIncidents.caseID}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      }
    );

    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setIncidentsByCaseID(result);
    } else {
      setIncidentsByCaseID([]);
    }
  }
  async function fetchFileByCaseID() {
    const response = await fetch(`/filesByCaseID/${formIncidents.caseID}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });

    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setIncidentsAndFiles(result);
    } else {
      setIncidentsAndFiles([]);
    }
  }
  async function fetchAllFiles() {
    const response = await fetch("/allFilesManagement", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllFiles(result);
    } else {
      setAllFiles([]);
    }

    // setSelectedTimeslot(result.slotTimes[0]);
  }
  async function fetchUsersList() {
    const response = await fetch("/allUsersList", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    setDataListUsers({
      idsUsers: result.ids,
      namesUsers: result.names,
    });
    // setDataListUsers(result);
  }
  async function fetchLastUniqId() {
    try {
      const response = await fetch("/lastUniqueCaseID", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      });
      const result = await response.json();
      const { nextUniqueCaseID, ...restData } = result[0];
      setFormIncidents((prevData) => ({
        ...prevData,
        caseID: nextUniqueCaseID,
      }));
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  }
  async function fetchIncidentOwners() {
    const response = await fetch("/allIncidentOwners", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setIncidentOwners(result);
    } else {
      setIncidentOwners([]);
    }
  }
  async function fetchAllClientIncidents() {
    const response = await fetch("/distinctIncidents", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllClientIncidents(result);
    } else {
      setAllClientIncidents([]);
    }
  }

  useEffect(() => {
    async function fetchClientsList() {
      try {
        const response = await fetch("/clientsList", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        });
        const result = await response.json();
        if (result.ids && result.names) {
          setDataListClients({
            idsOfClients: result.ids,
            namesOfclients: result.names,
          });
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }
    async function fetchCategoriesList() {
      const response = await fetch("/allCategoriesList", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      });
      const result = await response.json();
      setDataListCategory({
        idsCategory: result.ids,
        namesCategory: result.names,
      });
      // setDataListCategory(result);
    }
    fetchClientsList();
    fetchCategoriesList();
    fetchLastUniqId();
    fetchIncidentOwners();
    fetchIncidentDetailsByCaseID();
    fetchIncidentOwners();
    fetchAllClientIncidents();
    fetchUsersList();
    fetchFileByCaseID();
  }, []);

  const handleDialogDataOnClick = (rowData) => {
    const [day, month, year] = rowData.DueDate.split("-");
    const formattedDueDate = `${year}-${(
      "0" +
      (new Date(Date.parse(month + " 1, " + year)).getMonth() + 1)
    ).slice(-2)}-${day}`;
    setCaseIDForAPI(rowData.caseID);
    setFormIncidents({
      apiID: rowData.id,
      caseID: rowData.caseID,
      clientID: rowData.clientID,
      categoryID: rowData.categoryID,
      description: rowData.descriptionOfWork,
      status: rowData.status,
      comments: rowData.comments,
      assigntedTo: rowData.assigntedTo,
      caseOwner: rowData.caseOwner,
      dueDate: formattedDueDate,
    });

    setOpenTktDialog(true);
    fetchIncidentDetailsByCaseID();
    fetchFileByCaseID();
  };

  const handleSubmitAddIncidents = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Proceed with adding the holiday
      const response = await fetch("/InsertBulkIncidents", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            caseID: formIncidents.caseID,
            clientID: formIncidents.clientID,
            categoryID: formIncidents.categoryID,
            description: formIncidents.description,
            status: "Applied",
            comments: formIncidents.comments,
            assigntedTo: formIncidents.assigntedTo,
            caseOwner: formIncidents.caseOwner,
            dueDate: formIncidents.dueDate,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Client Added successfully!",
          life: 3000,
        });
        crearVariales();
        setAddIncident(false);
        fetchAllClientIncidents();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating Client.",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating Holiday.",
        life: 3000,
      });
    }

    setIsLoading(false);
  };

  const handleSubmitUpdateIncidents = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Proceed with adding the holiday
      const response = await fetch("/bulkUpdateIncidents", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            id: formIncidents.apiID,
            caseID: formIncidents.caseID,
            clientID: formIncidents.clientID,
            categoryID: formIncidents.categoryID,
            description: formIncidents.description,
            status: formIncidents.status,
            comments: formIncidents.comments,
            assigntedTo: formIncidents.assigntedTo,
            caseOwner: formIncidents.caseOwner,
            dueDate: formIncidents.dueDate,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Updated successfully!",
          life: 3000,
        });
        crearVariales();
        setAddIncident(false);
        setOpenTktDialog(false);
        fetchAllClientIncidents();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating details.",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating details.",
        life: 3000,
      });
    }

    setIsLoading(false);
  };
  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileSizeLimit = 50 * 1024 * 1024; // 50MB in bytes
      if (selectedFile.size > fileSizeLimit) {
        setFileError("File size must be less than 50MB");
        setUploadFile(null); // Clear the selected file
        toast.current.show({
          severity: "error",
          summary: "File Size Error",
          detail: "File size must be less than 50MB",
        });
      } else {
        setFileError("");
        setUploadFile(selectedFile);
        const extension = selectedFile.name
          .substring(selectedFile.name.lastIndexOf("."))
          .toLowerCase();

        setFormUpload({
          ...formUpload,
          fileType: extension,
        });
      }
    }
  };
  const handleSubmitUploadFile = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!uploadFile) {
      setFileError("Select A File.");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Select A File.",
        life: 3000,
      });
      setIsLoading(false);
      return;
    }

    if (uploadFile) {
      const restrictedExtensions = [".exe", ".bat", ".msi"];
      const extension = uploadFile.name
        .substring(uploadFile.name.lastIndexOf("."))
        .toLowerCase();

      if (restrictedExtensions.includes(extension)) {
        setFileError("File type not allowed. Kindly select a valid file.");
        toast.current.show({
          severity: "error",
          summary: "Invalid File Type",
          detail: "File type not allowed. Kindly select a valid file.",
        });
        return; // Stop the form submission
      }
    }

    const formData = new FormData();
    formData.append("caseID", formUpload.caseID);
    formData.append("clientID", formUpload.clientID);
    formData.append("descriptionOfUpload", formUpload.descriptionOfUpload);
    formData.append("uploadFile", uploadFile);
    formData.append("comments", formUpload.comments);
    formData.append("fileType", formUpload.fileType);
    formData.append("uploadedBy", formUpload.uploadedBy);

    try {
      const response = await fetch(`/uploadFiles`, {
        method: "POST",
        body: formData,
        // "Content-Type": "multipart/form-data",
      });

      if (response.ok) {
        setFormUpload({
          caseID: "",
          clientID: "",
          descriptionOfUpload: "",
          comments: "",
          fileType: "",
        });
        setUploadFile(null);
        //    setOpenAddDialog(false);
        fetchAllFiles();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "File Upload Successfull",
          life: 3000,
        });
        const input = document.getElementById("file");
        if (input) {
          input.value = "";
        }
        //    setTimeout(() => {
        //      fetGeneralTimeSlots();
        //    }, 2000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "File Upload failed!",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error:", error);

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "File Upload failed!",
        life: 3000,
      });
    }
    setIsLoading(false);
  };
  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        keyfilter={/^[a-zA-Z ]*$/}
        value={options.value}
        onChange={(e) =>
          options.editorCallback(e.target.value.substring(0, 150))
        }
      />
    );
  };

  const onRowEditIncidentOwners = async (e) => {
    let _tasks = [...incidentOwners];
    let { newData, index } = e;

    _tasks[index] = newData;

    setIncidentOwners(_tasks);

    try {
      const response = await fetch("/bulkUpdateIncidentOwners", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([newData]),
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Updated successfully!",
          life: 3000,
        });
        // triggerApiDataChange();
        fetchIncidentOwners();
        //  setError("");
      }

      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update data!",
          life: 3000,
        });
        // triggerApiDataChange();
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const handleSubmitAddIncidentOwners = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch("/InsertBulkIncidentOwners", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            fullName: fullName,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved Successfully!",
          life: 3000,
        });
        setFullName("");
        setAddIncidentOwners(false);
        fetchIncidentOwners();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating Details.",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating Holiday.",
        life: 3000,
      });
    }

    setIsLoading(false);
  };

  const handleRowClick = (rowData) => {
    return (
      <>
        <Button
          onClick={() => {
            handleDialogDataOnClick(rowData);
          }}
          text>
          {rowData.caseID}
        </Button>
      </>
    );
  };
  const statusBodyTemplate = (rowData) => {
    const statusSeverity = {
      Applied: "info",
      "In-Progress": "warning",
      Pending: "danger",
      Completed: "success",
    };

    return (
      <Tag
        value={rowData.status}
        severity={statusSeverity[rowData.status] || "info"}
      />
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={addIncident}
        style={{ width: "50vw" }}
        onHide={() => {
          setAddIncident(false);
          crearVariales();
        }}
        className="dialogAddForms">
        <h3 className="text-center">Add Incidents</h3>
        <div className="grid">
          <div className="col-12  lg:col-6">
            <div className="addClientLeftImg">
              <img src={AddClientImg} alt="" width="100%" />
            </div>
          </div>
          <div className="col-12 lg:col-6">
            <form onSubmit={handleSubmitAddIncidents}>
              <div className="flex flex-column gap-2 mb-2">
                <label htmlFor="Holiday Name" className="font-bold block">
                  Client
                  <span className="starForMandatoryInInputFields">*</span>
                </label>
                <Dropdown
                  value={formIncidents.clientID}
                  onChange={(e) => {
                    setFormIncidents({
                      ...formIncidents,
                      clientID: e.value,
                    });
                  }}
                  options={dataListClients.namesOfclients.map(
                    (name, index) => ({
                      label: name,
                      value: dataListClients.idsOfClients[index],
                    })
                  )}
                  placeholder="Select Client"
                  className="w-full"
                  filter
                  checkmark={true}
                  highlightOnSelect={true}
                />
              </div>
              <div className="flex flex-wrap gap-2 p-fluid">
                <label htmlFor="buttondisplay" className="font-bold block">
                  CategoryID
                  <span className="starForMandatoryInInputFields">*</span>
                </label>
                <Dropdown
                  value={formIncidents.categoryID}
                  onChange={(e) => {
                    setFormIncidents({
                      ...formIncidents,
                      categoryID: e.value,
                    });
                  }}
                  options={dataListCategory.namesCategory.map(
                    (name, index) => ({
                      label: name,
                      value: dataListCategory.idsCategory[index],
                    })
                  )}
                  // optionLabel="label"
                  placeholder="Select Category"
                  className="w-full"
                  filter
                  checkmark={true}
                  highlightOnSelect={true}
                />
              </div>
              <div className="flex flex-column gap-2 mb-2">
                <label htmlFor="Day" className="font-bold block">
                  Description
                  <span className="starForMandatoryInInputFields">*</span>
                </label>
                <InputText
                  aria-describedby="username-help"
                  value={formIncidents.description}
                  onChange={(e) => {
                    handleInputChange(
                      "description",
                      e.target.value.substring(0, 150)
                    );
                  }}
                />
              </div>
              <div className="flex flex-column gap-2 mb-2">
                <label htmlFor="Day" className="font-bold block">
                  Due Date
                  <span className="starForMandatoryInInputFields">*</span>
                </label>
                <InputText
                  aria-describedby="username-help"
                  type="date"
                  value={formIncidents.dueDate}
                  onChange={(e) => {
                    handleInputChange("dueDate", e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-column gap-2 mb-2">
                <label htmlFor="Day" className="font-bold block">
                  AssigntedTo
                  <span className="starForMandatoryInInputFields">*</span>
                </label>
                <Dropdown
                  id="floatingSelectGrid"
                  value={formIncidents.assigntedTo}
                  options={
                    dataListUsers && dataListUsers.namesUsers
                      ? dataListUsers.namesUsers.map((selectIdTwo, index) => ({
                          label: selectIdTwo,
                          value: dataListUsers.idsUsers[index],
                        }))
                      : []
                  }
                  placeholder="Select Assignee"
                  onChange={(e) =>
                    setFormIncidents({
                      ...formIncidents,
                      assigntedTo: e.value,
                    })
                  }
                  filter
                  checkmark={true}
                  highlightOnSelect={true}
                />
              </div>{" "}
              <div className="flex flex-column gap-2 mb-2">
                <label htmlFor="Day" className="font-bold block">
                  CaseOwner
                  <span className="starForMandatoryInInputFields">*</span>
                </label>
                <Dropdown
                  id="floatingSelectGrid"
                  value={formIncidents.caseOwner}
                  options={
                    dataListUsers && dataListUsers.namesUsers
                      ? dataListUsers.namesUsers.map((selectIdTwo, index) => ({
                          label: selectIdTwo,
                          value: dataListUsers.idsUsers[index],
                        }))
                      : []
                  }
                  placeholder="Select CaseOwner"
                  onChange={(e) =>
                    setFormIncidents({
                      ...formIncidents,
                      caseOwner: e.value,
                    })
                  }
                  filter
                  checkmark={true}
                  highlightOnSelect={true}
                />
              </div>
              <div className="flex flex-column gap-2 mb-2">
                <label htmlFor="Day" className="font-bold block">
                  Comments
                  <span className="starForMandatoryInInputFields">*</span>
                </label>
                <InputText
                  aria-describedby="username-help"
                  value={formIncidents.comments}
                  onChange={(e) => {
                    handleInputChange("comments", e.target.value);
                  }}
                />
              </div>
              <div className="flex justify-content-center mt-2">
                <Button
                  type="submit"
                  label="Submit"
                  loading={isLoading}
                  disabled={
                    !formIncidents.clientID ||
                    !formIncidents.categoryID ||
                    !formIncidents.description ||
                    !formIncidents.comments ||
                    !formIncidents.assigntedTo ||
                    !formIncidents.caseOwner
                  }
                  rounded
                  raised
                  className="w-full roundad"
                />
              </div>
            </form>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={openTktDialog}
        onHide={() => {
          setOpenTktDialog(false);
          crearVariales();
        }}
        className="dialogAddForms width-control"
        style={{ width: "75vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}>
        <div className="addEmployeeContainer">
          <h2 className="text-center">Manage Incident</h2>
          <div className="flex justify-content-end">
            <ToggleButton
              onLabel="Cancel"
              offLabel="Edit"
              onIcon="pi pi-check"
              offIcon="pi pi-times"
              checked={checked}
              onChange={(e) => setChecked(e.value)}
              className="p-0"
              rounded
            />
          </div>

          <form onSubmit={handleSubmitUpdateIncidents}>
            <table width={"100%"}>
              <tr>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    CaseID{" "}
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <InputText
                    keyfilter="int"
                    value={formIncidents.caseID}
                    placeholder="Employee ID"
                    readOnly
                    disabled
                    className="w-full"
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    Client
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <Dropdown
                    value={formIncidents.clientID}
                    onChange={(e) => {
                      setFormIncidents({
                        ...formIncidents,
                        clientID: e.value,
                      });
                    }}
                    options={dataListClients.namesOfclients.map(
                      (name, index) => ({
                        label: name,
                        value: dataListClients.idsOfClients[index],
                      })
                    )}
                    placeholder="Select Client"
                    className="w-full"
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                    disabled
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    Category
                  </label>
                  <Dropdown
                    value={formIncidents.categoryID}
                    onChange={(e) => {
                      setFormIncidents({
                        ...formIncidents,
                        categoryID: e.value,
                      });
                    }}
                    options={dataListCategory.namesCategory.map(
                      (name, index) => ({
                        label: name,
                        value: dataListCategory.idsCategory[index],
                      })
                    )}
                    // optionLabel="label"
                    placeholder="Select Category"
                    className="w-full"
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    Description
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formIncidents.description}
                    onChange={(e) => {
                      handleInputChange(
                        "description",
                        e.target.value.substring(0, 150)
                      );
                    }}
                    className="w-full"
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "0px",
                      left: "13px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    Due Date
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    type="date"
                    value={formIncidents.dueDate}
                    onChange={(e) => {
                      handleInputChange("dueDate", e.target.value);
                    }}
                    className="w-full"
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    AssigntedTo
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <Dropdown
                    id="floatingSelectGrid"
                    value={formIncidents.assigntedTo}
                    options={
                      dataListUsers && dataListUsers.namesUsers
                        ? dataListUsers.namesUsers.map(
                            (selectIdTwo, index) => ({
                              label: selectIdTwo,
                              value: dataListUsers.idsUsers[index],
                            })
                          )
                        : []
                    }
                    placeholder="Select Assignee"
                    onChange={(e) =>
                      setFormIncidents({
                        ...formIncidents,
                        assigntedTo: e.value,
                      })
                    }
                    className="w-full"
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    CaseOwner
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <Dropdown
                    id="floatingSelectGrid"
                    value={formIncidents.caseOwner}
                    options={
                      dataListUsers && dataListUsers.namesUsers
                        ? dataListUsers.namesUsers.map(
                            (selectIdTwo, index) => ({
                              label: selectIdTwo,
                              value: dataListUsers.idsUsers[index],
                            })
                          )
                        : []
                    }
                    placeholder="Select CaseOwner"
                    className="w-full"
                    onChange={(e) =>
                      setFormIncidents({
                        ...formIncidents,
                        caseOwner: e.value,
                      })
                    }
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    <span className="inPutFieldSmallLabel">Status</span>
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>

                  <Dropdown
                    value={formIncidents.status}
                    options={[
                      { label: "Applied", value: "Applied" },
                      { label: "In-Progress", value: "In-Progress" },
                      { label: "Pending", value: "Pending" },
                      { label: "Completed", value: "Completed" },
                    ]}
                    onChange={(e) =>
                      setFormIncidents({
                        ...formIncidents,
                        status: e.value,
                      })
                    }
                    placeholder="Select Status"
                    className="w-full"
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    <span className="inPutFieldSmallLabel">Comments</span>
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formIncidents.comments}
                    onChange={(e) => {
                      handleInputChange("comments", e.target.value);
                    }}
                    className="w-full"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3} className="text-center">
                  {checked && (
                    <Button
                      type="submit"
                      label="Submit"
                      disabled={
                        !formIncidents.clientID ||
                        !formIncidents.categoryID ||
                        !formIncidents.description ||
                        !formIncidents.comments ||
                        !formIncidents.assigntedTo ||
                        !formIncidents.caseOwner
                      }
                      loading={isLoading}
                      className="roundad"
                    />
                  )}
                </td>
              </tr>
            </table>
          </form>

          <TabView scrollable className="mt-1">
            <TabPanel header="Updates">
              <div className="flex justify-content-end">
                <Inplace closable className="mb-2">
                  <InplaceDisplay>
                    <BiFile />
                    {formUpload.fileType || "Click to Add Update"}
                  </InplaceDisplay>
                  <InplaceContent>
                    <InputText placeholder="IncidentID" />{" "}
                    <InputText placeholder="Comment" />
                    <Button label="Submit" rounded className="ml-2" />
                  </InplaceContent>
                </Inplace>
              </div>
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={incidentsByCaseID}
                size="small"
                paginator
                rows={10}
                // rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="350px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column
                  header="Update"
                  field="updateComments"
                  className=""
                  sortable
                  // filter
                />
                <Column
                  header="CreatedBy"
                  field="updatedCommentsBy"
                  className="allTableTDCenterCommon"
                  sortable
                  // filter
                />

                <Column
                  header="CreatedOn"
                  field="CreatedOn"
                  sortable
                  // filter
                />
                <Column
                  header="Action"
                  className=""
                  sortable
                  // filter
                />
              </DataTable>
            </TabPanel>
            <TabPanel header="Files">
              <div className="flex justify-content-end">
                <Inplace closable className="mb-2">
                  <InplaceDisplay>
                    <BiFile />
                    {"Click to Add File"}
                  </InplaceDisplay>
                  <InplaceContent>
                    <InputText
                      aria-describedby="username-help"
                      type="file"
                      // accept=".png, .jpeg,.jpg,application/pdf,.docx, .txt, .html, .sql"
                      // className="datemobileFile"
                      onChange={onFileChange}
                      className="w-15rem"
                    />
                    <InputText placeholder="Description" className="w-12rem" />
                    <InputText placeholder="Comment" className="w-12rem" />
                    <Button label="Submit" rounded />
                  </InplaceContent>
                </Inplace>
              </div>

              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={incidentsAndFiles}
                size="small"
                paginator
                rows={10}
                // showGridlines
                // rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="350px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column
                  header="Client"
                  field="clientName"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="Description	"
                  field="description"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="Category"
                  field="categoryName"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="Status"
                  field="status"
                  className="allTableTDCenterCommon"
                  sortable
                  body={statusBodyTemplate}
                  filter
                />
                <Column
                  header="DueDate"
                  field="DueDate"
                  sortable
                  className="allTableTDCenterCommon"
                />
              </DataTable>
            </TabPanel>
          </TabView>
        </div>
      </Dialog>
      <Dialog
        visible={addIncidentOwners}
        onHide={() => {
          setAddIncidentOwners(false);
          setFullName("");
        }}
        className="dbaAllDialogs">
        <Card header="">
          <h3 className="text-center">Add Incident Owners</h3>
          <form onSubmit={handleSubmitAddIncidentOwners}>
            <label htmlFor="Holiday Name" className="font-bold block">
              Full Name
              <span className="starForMandatoryInInputFields">*</span>
            </label>
            <InputText
              aria-describedby="username-help"
              value={fullName}
              keyfilter={/^[a-zA-Z\s]+$/}
              onChange={(e) => {
                setFullName(e.target.value.substring(0, 25));
              }}
            />
            <div>
              <Button
                type="submit"
                label="Submit"
                loading={isLoading}
                disabled={!fullName}
                rounded
                raised
                className="roundad w-full mt-3 mb-2"
              />
            </div>
          </form>
        </Card>
      </Dialog>
      <div>
        <div className="flex justify-content-between">
          <ToggleButton
            onLabel=" View Tickets"
            offLabel="IncidentOwners"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            checked={checked}
            onChange={(e) => setChecked(e.value)}
          />
          {!checked && (
            <div>
              <span>Technology:</span>
              <Dropdown
                value={formIncidents.status}
                options={[
                  { label: "MSSQL", value: "MSSQL" },
                  { label: "MYSQL", value: "MYSQL" },
                  { label: "ORACLE", value: "ORACLE" },
                ]}
                onChange={(e) =>
                  setFormIncidents({
                    ...formIncidents,
                    status: e.value,
                  })
                }
                placeholder="Technology"
                rounded
              />
              <span className="ml-2">Clients:</span>
              <Dropdown
                value={formIncidents.status}
                options={[
                  { label: "MSSQL", value: "MSSQL" },
                  { label: "MYSQL", value: "MYSQL" },
                  { label: "ORACLE", value: "ORACLE" },
                ]}
                onChange={(e) =>
                  setFormIncidents({
                    ...formIncidents,
                    status: e.value,
                  })
                }
                placeholder="Technology"
                rounded
              />
            </div>
          )}
          <div>
            <Button
              label="Add"
              onClick={() => {
                setAddIncident(true);
              }}
            />
          </div>
        </div>
        <Divider />
        {checked && (
          <DataTable
            tableStyle={{ minWidth: "50rem" }}
            value={incidentOwners}
            size="small"
            paginator
            rows={10}
            showGridlines
            editMode="row"
            dataKey="assignID"
            onRowEditComplete={onRowEditIncidentOwners}
            rowsPerPageOptions={[10, 25, 50, 100]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            removableSort
            scrollable
            scrollHeight="250px"
            className="FilterTable allDataTableThCenterCommon m-2"
            emptyMessage="No Data found.">
            <Column
              header="ID"
              field="assignID"
              frozen
              filter
              className="allTableTDCenterCommon"
            />
            <Column
              header="Full Name"
              field="fullName"
              className="allTableTDCenterCommon"
              sortable
              filter
              editor={(options) => textEditor(options)}></Column>
            <Column rowEditor></Column>
          </DataTable>
        )}
        {!checked && (
          <DataTable
            tableStyle={{ minWidth: "50rem" }}
            value={allClientIncidents}
            size="small"
            paginator
            rows={10}
            showGridlines
            rowsPerPageOptions={[10, 25, 50, 100]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            removableSort
            scrollable
            scrollHeight="350px"
            className="FilterTable allDataTableThCenterCommon m-2"
            emptyMessage="No Data found.">
            <Column
              header="IncidentID"
              field="caseID"
              frozen
              filter
              className="allTableTDCenterCommon"
              body={handleRowClick}
            />
            <Column
              header="Client"
              field="clientName"
              className="allTableTDCenterCommon"
              sortable
              filter
            />
            <Column
              header="Description	"
              field="description"
              className="allTableTDCenterCommon"
              sortable
              filter
            />
            <Column
              header="Category"
              field="categoryName"
              className="allTableTDCenterCommon"
              sortable
              filter
            />
            <Column
              header="Status"
              field="status"
              className="allTableTDCenterCommon"
              sortable
              body={statusBodyTemplate}
              filter
            />
            <Column
              header="DueDate"
              field="DueDate"
              sortable
              className="allTableTDCenterCommon"
            />
          </DataTable>
        )}
      </div>
    </div>
  );
}

export default MonitorIncidents;
