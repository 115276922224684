import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import AddClientImg from "../../../images/Add Client.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

function CustRequestAndServices() {
  const toast = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const clientEmail = sessionStorage.getItem("loginUserEmail");
  const [openTktDialog, setOpenTktDialog] = useState(false);
  const [allClientIncidents, setAllClientIncidents] = useState([]);
  const [allClientFilesIncidents, setAllClientFilesIncidents] = useState([]);
  const [dataListClients, setDataListClients] = useState({
    idsOfClients: [],
    namesOfclients: [],
  });
  const [dataListCases, setDataListCases] = useState({
    idsOfClients: [],
    namesOfCases: [],
  });
  const [uploadFile, setUploadFile] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const role = sessionStorage.getItem("roleName");
  const [formUpload, setFormUpload] = useState({
    caseID: "",
    clientID: "",
    descriptionOfUpload: "",
    comments: "",
    fileType: "",
    uploadedBy: role,
  });
  const [dataListCategory, setDataListCategory] = useState({
    idsCategory: [],
    namesCategory: [],
  });
  const [fileError, setFileError] = useState("");
  const [dataListUsers, setDataListUsers] = useState({
    idsUsers: [],
    namesUsers: [],
  });

  const [formIncidents, setFormIncidents] = useState({
    apiID: "",
    caseID: "",
    clientID: "",
    categoryID: "",
    description: "",
    status: "",
    comments: "",
    assigntedTo: "",
    caseOwner: "",
    dueDate: "",
  });

  async function fetchAllClientIncidents() {
    const response = await fetch("/allClientIncidents", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllClientIncidents(result);
    } else {
      setAllClientIncidents([]);
    }
  }

  async function fetchAllClientFilesIncidents() {
    const response = await fetch(
      `/incidentAndFilesByClntEmail/${clientEmail}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      }
    );
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllClientFilesIncidents(result);
    } else {
      setAllClientFilesIncidents([]);
    }
  }

  useEffect(() => {
    async function fetchClientsList() {
      try {
        const response = await fetch("/clientsList", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        });
        const result = await response.json();
        if (result.ids && result.names) {
          setDataListClients({
            idsOfClients: result.ids,
            namesOfclients: result.names,
          });
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }

    async function fetchCategoriesList() {
      const response = await fetch("/allCategoriesList", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      });
      const result = await response.json();
      setDataListCategory({
        idsCategory: result.ids,
        namesCategory: result.names,
      });
      // setDataListCategory(result);
    }

    async function fetchUsersList() {
      const response = await fetch("/allUsersList", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      });
      const result = await response.json();
      setDataListUsers({
        idsUsers: result.ids,
        namesUsers: result.names,
      });
      // setDataListUsers(result);
    }
    fetchAllClientIncidents();
    fetchCategoriesList();
    fetchClientsList();
    fetchUsersList();
    fetchAllClientFilesIncidents();
  }, []);

  const crearVariales = () => {
    setFormIncidents({
      apiID: "",
      clientID: "",
      categoryID: "",
      description: "",
      comments: "",
      assigntedTo: "",
      caseOwner: "",
      dueDate: "",
      status: "",
    });
  };
  const handleInputChange = (name, value) => {
    setFormIncidents((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputFilesChange = (name, value) => {
    setFormUpload((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileSizeLimit = 50 * 1024 * 1024; // 50MB in bytes
      if (selectedFile.size > fileSizeLimit) {
        setFileError("File size must be less than 50MB");
        setUploadFile(null); // Clear the selected file
        toast.current.show({
          severity: "error",
          summary: "File Size Error",
          detail: "File size must be less than 50MB",
        });
      } else {
        setFileError("");
        setUploadFile(selectedFile);
        const extension = selectedFile.name
          .substring(selectedFile.name.lastIndexOf("."))
          .toLowerCase();

        setFormUpload({
          ...formUpload,
          fileType: extension,
        });
      }
    }
  };

  const handleDialogDataOnClick = (rowData) => {
    const [day, month, year] = rowData.DueDate.split("-");
    const formattedDueDate = `${year}-${(
      "0" +
      (new Date(Date.parse(month + " 1, " + year)).getMonth() + 1)
    ).slice(-2)}-${day}`;
    setFormIncidents({
      apiID: rowData.id,
      caseID: rowData.caseID,
      clientID: rowData.clientID,
      categoryID: rowData.categoryID,
      description: rowData.descriptionOfWork,
      status: rowData.status,
      comments: rowData.comments,
      assigntedTo: rowData.assigntedTo,
      caseOwner: rowData.caseOwner,
      dueDate: formattedDueDate,
    });

    setOpenTktDialog(true);
  };

  const handleRowClick = (rowData) => {
    return (
      <>
        <Button
          onClick={() => {
            handleDialogDataOnClick(rowData);
          }}
          text>
          {rowData.caseID}
        </Button>
      </>
    );
  };

  const handleSubmitUpdateIncidents = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Proceed with adding the holiday
      const response = await fetch("/bulkUpdateIncidents", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            id: formIncidents.apiID,
            caseID: formIncidents.caseID,
            clientID: formIncidents.clientID,
            categoryID: formIncidents.categoryID,
            description: formIncidents.description,
            status: formIncidents.status,
            comments: formIncidents.comments,
            assigntedTo: formIncidents.assigntedTo,
            caseOwner: formIncidents.caseOwner,
            dueDate: formIncidents.dueDate,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Updated successfully!",
          life: 3000,
        });
        crearVariales();
        // setAddIncident(false);
        setOpenTktDialog(false);
        fetchAllClientIncidents();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating details.",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating details.",
        life: 3000,
      });
    }

    setIsLoading(false);
  };

  const handleSubmitUploadFile = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!uploadFile) {
      setFileError("Select A File.");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Select A File.",
        life: 3000,
      });
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("caseID", formUpload.caseID);
    formData.append("clientID", formUpload.clientID);
    formData.append("descriptionOfUpload", formUpload.descriptionOfUpload);
    formData.append("uploadFile", uploadFile);
    formData.append("comments", formUpload.comments);
    formData.append("fileType", formUpload.fileType);
    formData.append("uploadedBy", formUpload.uploadedBy);

    try {
      const response = await fetch(`/uploadFiles`, {
        method: "POST",
        body: formData,
        // "Content-Type": "multipart/form-data",
      });

      if (response.ok) {
        setFormUpload({
          descriptionOfUpload: "",
          comments: "",
          fileType: "",
        });
        setUploadFile(null);
        setOpenAddDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "File Upload Successfull",
          life: 3000,
        });
        const input = document.getElementById("file");
        if (input) {
          input.value = "";
        }
        fetchAllClientFilesIncidents();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "File Upload failed!",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error:", error);

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "File Upload failed!",
        life: 3000,
      });
    }
    setIsLoading(false);
  };

  const handleDownload = (fileData) => {
    const byteCharacters = atob(fileData.uploadFile);
    const byteArray = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([byteArray], {
      type: getFileMimeType(fileData.fileType),
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileData.caseID}${fileData.fileType}`;
    link.click();
  };

  const getFileMimeType = (fileType) => {
    switch (fileType) {
      case ".pdf":
        return "application/pdf";
      case ".docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case ".jpg":
      case ".jpeg":
        return "image/jpeg";
      case ".png":
        return "image/png";
      case ".txt":
        return "text/plain";
      default:
        return "application/octet-stream";
    }
  };

  const handleDialogDataFile = (rowData) => {
    setFormUpload({
      caseID: rowData.caseID,
      clientID: rowData.clientID,
    });
    setOpenAddDialog(true);
  };

  const handleRowClickFile = (rowData) => {
    return (
      <>
        <Button
          onClick={() => {
            handleDialogDataFile(rowData);
          }}
          rounded>
          Add File
        </Button>
      </>
    );
  };
  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={openTktDialog}
        onHide={() => {
          setOpenTktDialog(false);
        }}
        className="dialogAddForms width-control">
        <div className="addEmployeeContainer">
          <h4 className="text-center">Add Comments</h4>
          <form onSubmit={handleSubmitUpdateIncidents}>
            <table width={"100%"}>
              <tr>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    CaseID{" "}
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <InputText
                    keyfilter="int"
                    value={formIncidents.caseID}
                    placeholder="Employee ID"
                    readOnly
                    disabled
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    Client
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <Dropdown
                    value={formIncidents.clientID}
                    onChange={(e) => {
                      setFormIncidents({
                        ...formIncidents,
                        clientID: e.value,
                      });
                    }}
                    options={dataListClients.namesOfclients.map(
                      (name, index) => ({
                        label: name,
                        value: dataListClients.idsOfClients[index],
                      })
                    )}
                    placeholder="Select Client"
                    className="w-full"
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                    disabled
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    Category
                  </label>
                  <Dropdown
                    value={formIncidents.categoryID}
                    onChange={(e) => {
                      setFormIncidents({
                        ...formIncidents,
                        categoryID: e.value,
                      });
                    }}
                    options={dataListCategory.namesCategory.map(
                      (name, index) => ({
                        label: name,
                        value: dataListCategory.idsCategory[index],
                      })
                    )}
                    // optionLabel="label"
                    placeholder="Select Category"
                    className="w-full"
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    Description
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formIncidents.description}
                    onChange={(e) => {
                      handleInputChange(
                        "description",
                        e.target.value.substring(0, 150)
                      );
                    }}
                    disabled
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "0px",
                      left: "13px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    Due Date
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    type="date"
                    value={formIncidents.dueDate}
                    onChange={(e) => {
                      handleInputChange("dueDate", e.target.value);
                    }}
                    className="w-full"
                    disabled
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    AssigntedTo
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <Dropdown
                    id="floatingSelectGrid"
                    value={formIncidents.assigntedTo}
                    options={
                      dataListUsers && dataListUsers.namesUsers
                        ? dataListUsers.namesUsers.map(
                            (selectIdTwo, index) => ({
                              label: selectIdTwo,
                              value: dataListUsers.idsUsers[index],
                            })
                          )
                        : []
                    }
                    placeholder="Select Assignee"
                    onChange={(e) =>
                      setFormIncidents({
                        ...formIncidents,
                        assigntedTo: e.value,
                      })
                    }
                    className="w-full"
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    CaseOwner
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <Dropdown
                    id="floatingSelectGrid"
                    value={formIncidents.caseOwner}
                    options={
                      dataListUsers && dataListUsers.namesUsers
                        ? dataListUsers.namesUsers.map(
                            (selectIdTwo, index) => ({
                              label: selectIdTwo,
                              value: dataListUsers.idsUsers[index],
                            })
                          )
                        : []
                    }
                    placeholder="Select CaseOwner"
                    className="w-full"
                    onChange={(e) =>
                      setFormIncidents({
                        ...formIncidents,
                        caseOwner: e.value,
                      })
                    }
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                    disabled
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    <span className="inPutFieldSmallLabel">Status</span>
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>

                  <Dropdown
                    value={formIncidents.status}
                    options={[
                      { label: "Applied", value: "Applied" },
                      { label: "In-Progress", value: "In-Progress" },
                      { label: "Pending", value: "Pending" },
                      { label: "Completed", value: "Completed" },
                    ]}
                    onChange={(e) =>
                      setFormIncidents({
                        ...formIncidents,
                        status: e.value,
                      })
                    }
                    placeholder="Select Status"
                    className="w-full"
                    disabled
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    <span className="inPutFieldSmallLabel">Comments</span>
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formIncidents.comments}
                    onChange={(e) => {
                      handleInputChange("comments", e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={{ position: "relative" }}>
                  <label
                    style={{
                      position: "absolute",
                      zIndex: "99",
                      backgroundColor: "white",
                      top: "3px",
                      left: "10px",
                      padding: "0px 2px",
                    }}
                    className="labelforinputs"
                    htmlFor="">
                    <span className="inPutFieldSmallLabel">Comments</span>
                    <span className="starForMandatoryInInputFields"> *</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formIncidents.comments}
                    onChange={(e) => {
                      handleInputChange("comments", e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-center" colSpan={3}>
                  <Button
                    type="submit"
                    label="Submit"
                    disabled={
                      !formIncidents.clientID ||
                      !formIncidents.categoryID ||
                      !formIncidents.description ||
                      !formIncidents.comments ||
                      !formIncidents.assigntedTo ||
                      !formIncidents.caseOwner
                    }
                    loading={isLoading}
                    className="roundad w-15rem"
                  />
                </td>
              </tr>
            </table>
          </form>
        </div>
      </Dialog>
      <Dialog
        visible={openAddDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setOpenAddDialog(false);
        }}>
        <Card className="shadow-5 AddHolidayyform">
          <h3 className="text-center">Upload Files</h3>
          <div className="grid">
            <div className="col-12  lg:col-6">
              <div className="addClientLeftImg">
                <img src={AddClientImg} alt="" width="100%" />
              </div>
            </div>
            <div className="col-12 lg:col-6">
              <form onSubmit={handleSubmitUploadFile}>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Holiday Name" className="font-bold block">
                    CaseID
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    value={formUpload.caseID}
                    // className="datemobileFile"
                    disabled
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    Client
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <Dropdown
                    value={formUpload.clientID}
                    onChange={(e) => {
                      setFormUpload({
                        ...formUpload,
                        clientID: e.value,
                      });
                    }}
                    options={dataListClients.namesOfclients.map(
                      (name, index) => ({
                        label: name,
                        value: dataListClients.idsOfClients[index],
                      })
                    )}
                    placeholder="Select Client"
                    className="w-full"
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                    disabled
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    Description
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    value={formUpload.descriptionOfUpload}
                    // className="datemobileFile"
                    onChange={(e) => {
                      handleInputFilesChange(
                        "descriptionOfUpload",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    File
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    type="file"
                    accept=".png, .jpeg,.jpg,application/pdf,.docx, .txt, .html, .sql"
                    // className="datemobileFile"
                    onChange={onFileChange}
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    Comments
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    value={formUpload.comments}
                    // className="datemobileFile"
                    onChange={(e) => {
                      handleInputFilesChange(
                        "comments",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>

                <div className="flex justify-content-center mt-3">
                  <Button
                    type="submit"
                    label={isLoading ? "Uploading..." : "Upload"}
                    disabled={
                      !formUpload.clientID ||
                      !formUpload.caseID ||
                      !formUpload.descriptionOfUpload ||
                      !formUpload.comments ||
                      !uploadFile
                    }
                    rounded
                    raised
                    className="w-full roundad"
                  />
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Dialog>
      <Card className="m-5">
        <TabView>
          <TabPanel header="Incidents">
            <DataTable
              tableStyle={{ minWidth: "50rem" }}
              value={allClientFilesIncidents}
              size="small"
              paginator
              rows={10}
              showGridlines
              rowsPerPageOptions={[10, 25, 50, 100]}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              removableSort
              scrollable
              scrollHeight="350px"
              className="FilterTable allDataTableThCenterCommon m-2"
              emptyMessage="No Data found.">
              <Column
                header="IncidentID"
                field="caseID"
                frozen
                filter
                className="allTableTDCenterCommon"
                body={handleRowClick}
              />
              <Column
                header="Client"
                field="clientName"
                className="allTableTDCenterCommon"
                sortable
                filter
              />
              <Column
                header="Description	"
                field="descriptionOfWork"
                className="allTableTDCenterCommon"
                sortable
                filter
              />
              <Column
                header="Category"
                field="categoryName"
                className="allTableTDCenterCommon"
                sortable
                filter
              />
              <Column
                header="Status"
                field="status"
                className="allTableTDCenterCommon"
                sortable
                filter
              />
              <Column
                header="DueDate"
                field="DueDate"
                sortable
                className="allTableTDCenterCommon"
              />
            </DataTable>
          </TabPanel>
          <TabPanel header="Files">
            <div className="firstdivClients">
              <h2 className="">Manage Files</h2>
            </div>
            <DataTable
              tableStyle={{ minWidth: "50rem" }}
              value={allClientFilesIncidents}
              size="small"
              paginator
              rows={10}
              showGridlines
              rowsPerPageOptions={[10, 25, 50, 100]}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              removableSort
              scrollable
              scrollHeight="350px"
              className="FilterTable allDataTableThCenterCommon m-2"
              emptyMessage="No Data found.">
              <Column
                header="IncidentID"
                field="caseID"
                frozen
                filter
                className="allTableTDCenterCommon"
              />
              <Column
                header="Client"
                field="clientName"
                className="allTableTDCenterCommon"
                sortable
                filter
              />
              <Column
                header="Description	"
                field="descriptionOfUpload"
                className="allTableTDCenterCommon"
                sortable
                filter
              />
              <Column
                header="File"
                field="fileType"
                className="allTableTDCenterCommon"
                sortable
                filter
              />
              <Column
                header="Status"
                field="status"
                className="allTableTDCenterCommon"
                sortable
                filter
              />
              <Column
                header="Comments"
                field="comments"
                sortable
                className="allTableTDCenterCommon"
              />
              <Column
                header="Action"
                body={(rowData) => (
                  <Button
                    label="Download"
                    icon="pi pi-download"
                    onClick={() => handleDownload(rowData)}
                    className="p-button-outlined p-button-sm"
                  />
                )}
                className="allTableTDCenterCommon"
              />
              <Column header="Add" body={handleRowClickFile}></Column>
            </DataTable>
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}

export default CustRequestAndServices;
