import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AddClientImg from "../../../images/Add Client.png";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import Pdf from "../../../images/files/pdf.jpg";
import xlsx from "../../../images/files/xlsx .png";
import xls from "../../../images/files/xls.jpg";
import docx from "../../../images/files/docx.png";
import doc from "../../../images/files/Doc.jpg";
import png from "../../../images/files/png.png";
import jpg from "../../../images/files/jpg.png";

function FilesManagement() {
  const toast = useRef();
  const [clientDetails, setClientDetails] = useState("");
  const role = sessionStorage.getItem("roleName");
  const [allFiles, setAllFiles] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [dataListClients, setDataListClients] = useState({
    idsOfClients: [],
    namesOfclients: [],
  });
  const [dataListCases, setDataListCases] = useState({
    idsOfClients: [],
    namesOfCases: [],
  });
  const [formUpload, setFormUpload] = useState({
    caseID: "",
    clientID: "",
    descriptionOfUpload: "",
    comments: "",
    fileType: "",
    uploadedBy: role,
  });

  const [uploadFile, setUploadFile] = useState(null);
  const [fileError, setFileError] = useState("");

  // Function to render file icons based on file type
  const fileIconTemplate = (rowData) => {
    const fileIcons = {
      pdf: (
        <img src={Pdf} alt="PDF" style={{ width: "60px", height: "50px" }} />
      ),
      jpg: (
        <img src={jpg} alt="Image" style={{ width: "60px", height: "50px" }} />
      ),
      png: (
        <img src={png} alt="Image" style={{ width: "60px", height: "50px" }} />
      ),
      doc: (
        <img src={doc} alt="Doc" style={{ width: "60px", height: "50px" }} />
      ),
      docx: (
        <img src={docx} alt="Doc" style={{ width: "60px", height: "50px" }} />
      ),
      xls: (
        <img src={xls} alt="Excel" style={{ width: "60px", height: "50px" }} />
      ),
      xlsx: (
        <img src={xlsx} alt="Excel" style={{ width: "60px", height: "50px" }} />
      ),
    };

    // Extract the file extension from the fileType field
    const fileExtension = rowData.fileType.split(".").pop();

    // Return the corresponding Tag component or a default one
    return (
      fileIcons[fileExtension] || (
        <Tag icon="pi pi-file" severity="warning" value="File" />
      )
    );
  };

  const fetchClientID = async (selectedCaseID) => {
    const response = await fetch(`/clientID/${selectedCaseID}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    const clientID = result[0] ? result[0][0] : null;
    setFormUpload((prevData) => ({
      ...prevData,
      clientID: clientID,
    }));
  };

  async function fetGeneralTimeSlots() {
    const response = await fetch("/generalTimeSlots", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    setClientDetails(result);
    // setSelectedTimeslot(result.slotTimes[0]);
  }
  async function fetchAllFiles() {
    const response = await fetch("/allFilesManagement", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllFiles(result);
    } else {
      setAllFiles([]);
    }

    // setSelectedTimeslot(result.slotTimes[0]);
  }

  useEffect(() => {
    async function fetchClientsList() {
      try {
        const response = await fetch("/clientsList", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        });
        const result = await response.json();
        if (result.ids && result.names) {
          setDataListClients({
            idsOfClients: result.ids,
            namesOfclients: result.names,
          });
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }

    async function fetchCases() {
      try {
        const response = await fetch("/incidentsList", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        });
        const result = await response.json();
        if (result.ids && result.names) {
          setDataListCases({
            idsOfClients: result.ids,
            namesOfCases: result.names,
          });
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }
    fetGeneralTimeSlots();
    fetchClientsList();
    fetchAllFiles();

    fetchCases();
  }, []);

  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileSizeLimit = 50 * 1024 * 1024; // 50MB in bytes
      if (selectedFile.size > fileSizeLimit) {
        setFileError("File size must be less than 50MB");
        setUploadFile(null); // Clear the selected file
        toast.current.show({
          severity: "error",
          summary: "File Size Error",
          detail: "File size must be less than 50MB",
        });
      } else {
        setFileError("");
        setUploadFile(selectedFile);
        const extension = selectedFile.name
          .substring(selectedFile.name.lastIndexOf("."))
          .toLowerCase();

        setFormUpload({
          ...formUpload,
          fileType: extension,
        });
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Check if the file size is within the limit (10MB)
    if (selectedFile.size > 10485760) {
      setFileError("File size should not exceed 10MB.");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "File size should not exceed 10MB.",
        life: 3000,
      });
      setUploadFile(null);
      // setPreviewUrl(null);
    } else {
      setFileError("");
      setUploadFile(selectedFile);
    }
  };

  const handleInputChange = (name, value) => {
    setFormUpload((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmitUploadFile = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!uploadFile) {
      setFileError("Select A File.");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Select A File.",
        life: 3000,
      });
      setIsLoading(false);
      return;
    }

    if (uploadFile) {
      const restrictedExtensions = [".exe", ".bat", ".msi"];
      const extension = uploadFile.name
        .substring(uploadFile.name.lastIndexOf("."))
        .toLowerCase();

      if (restrictedExtensions.includes(extension)) {
        setFileError("File type not allowed. Kindly select a valid file.");
        toast.current.show({
          severity: "error",
          summary: "Invalid File Type",
          detail: "File type not allowed. Kindly select a valid file.",
        });
        return; // Stop the form submission
      }
    }

    const formData = new FormData();
    formData.append("caseID", formUpload.caseID);
    formData.append("clientID", formUpload.clientID);
    formData.append("descriptionOfUpload", formUpload.descriptionOfUpload);
    formData.append("uploadFile", uploadFile);
    formData.append("comments", formUpload.comments);
    formData.append("fileType", formUpload.fileType);
    formData.append("uploadedBy", formUpload.uploadedBy);

    try {
      const response = await fetch(`/uploadFiles`, {
        method: "POST",
        body: formData,
        // "Content-Type": "multipart/form-data",
      });

      if (response.ok) {
        setFormUpload({
          caseID: "",
          clientID: "",
          descriptionOfUpload: "",
          comments: "",
          fileType: "",
        });
        setUploadFile(null);
        setOpenAddDialog(false);
        fetchAllFiles();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "File Upload Successfull",
          life: 3000,
        });
        const input = document.getElementById("file");
        if (input) {
          input.value = "";
        }
        setTimeout(() => {
          fetGeneralTimeSlots();
        }, 2000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "File Upload failed!",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error:", error);

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "File Upload failed!",
        life: 3000,
      });
    }
    setIsLoading(false);
  };

  const handleDownload = (fileData) => {
    const byteCharacters = atob(fileData.uploadFile);
    const byteArray = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([byteArray], {
      type: getFileMimeType(fileData.fileType),
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileData.caseID}${fileData.fileType}`;
    link.click();
  };

  const getFileMimeType = (fileType) => {
    switch (fileType) {
      case ".pdf":
        return "application/pdf";
      case ".docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case ".jpg":
      case ".jpeg":
        return "image/jpeg";
      case ".png":
        return "image/png";
      case ".txt":
        return "text/plain";
      default:
        return "application/octet-stream";
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex justify-content-end">
        <div>
          <Button
            label="Upload"
            style={{ backgroundColor: "#0055cc" }}
            onClick={() => {
              setOpenAddDialog(true);
            }}
            rounded
          />
        </div>
      </div>
      <Dialog
        visible={openAddDialog}
        style={{ width: "50vw" }}
        onHide={() => {
          setOpenAddDialog(false);
        }}>
        <Card className="shadow-5 AddHolidayyform">
          <h3 className="text-center">Upload Files</h3>
          <div className="grid">
            <div className="col-12  lg:col-6">
              <div className="addClientLeftImg">
                <img src={AddClientImg} alt="" width="100%" />
              </div>
            </div>
            <div className="col-12 lg:col-6">
              <form onSubmit={handleSubmitUploadFile}>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Holiday Name" className="font-bold block">
                    CaseID
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <Dropdown
                    value={formUpload.caseID}
                    onChange={(e) => {
                      setFormUpload({
                        ...formUpload,
                        caseID: e.value,
                      });
                      fetchClientID(e.value);
                    }}
                    options={dataListCases.namesOfCases.map((name, index) => ({
                      label: name,
                      value: dataListCases.namesOfCases[index],
                    }))}
                    placeholder="Select CaseID"
                    className="w-full"
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    Client
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <Dropdown
                    value={formUpload.clientID}
                    onChange={(e) => {
                      setFormUpload({
                        ...formUpload,
                        clientID: e.value,
                      });
                    }}
                    options={dataListClients.namesOfclients.map(
                      (name, index) => ({
                        label: name,
                        value: dataListClients.idsOfClients[index],
                      })
                    )}
                    placeholder="Select Client"
                    className="w-full"
                    filter
                    checkmark={true}
                    highlightOnSelect={true}
                    disabled
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    Description
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    value={formUpload.descriptionOfUpload}
                    // className="datemobileFile"
                    onChange={(e) => {
                      handleInputChange(
                        "descriptionOfUpload",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    File
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    type="file"
                    // accept=".png, .jpeg,.jpg,application/pdf,.docx, .txt, .html, .sql"
                    // className="datemobileFile"
                    onChange={onFileChange}
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    Comments
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    value={formUpload.comments}
                    // className="datemobileFile"
                    onChange={(e) => {
                      handleInputChange(
                        "comments",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>

                <div className="flex justify-content-center mt-3">
                  <Button
                    type="submit"
                    label={isLoading ? "Uploading..." : "Upload"}
                    disabled={
                      !formUpload.clientID ||
                      !formUpload.caseID ||
                      !formUpload.descriptionOfUpload ||
                      !formUpload.comments ||
                      !uploadFile
                    }
                    rounded
                    raised
                    className="w-full roundad"
                  />
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Dialog>
      <Divider />
      <DataTable
        tableStyle={{ minWidth: "50rem" }}
        value={allFiles}
        size="small"
        paginator
        rows={10}
        showGridlines
        rowsPerPageOptions={[10, 25, 50, 100]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        removableSort
        scrollable
        scrollHeight="250px"
        className="FilterTable allDataTableThCenterCommon m-2"
        emptyMessage="No Data found.">
        <Column
          header="ID"
          field="id"
          frozen
          filter
          className="allTableTDCenterCommon"
        />
        <Column
          header="CaseID"
          field="caseID"
          className="allTableTDCenterCommon"
          sortable
          filter
        />{" "}
        <Column
          header="Description"
          field="descriptionOfUpload"
          className="allTableTDCenterCommon"
          sortable
          filter
        />{" "}
        <Column
          header="File"
          field="fileType"
          className="allTableTDCenterCommon"
          sortable
          filter
          body={fileIconTemplate}
        />
        <Column
          header="UploadedBy"
          field="uploadedBy"
          className="allTableTDCenterCommon"
        />
        <Column
          header="Comments"
          field="comments"
          className="allTableTDCenterCommon"
          sortable
          filter
        />{" "}
        <Column
          header="Action"
          body={(rowData) => (
            <Button
              label="Download"
              icon="pi pi-download"
              onClick={() => handleDownload(rowData)}
              className="p-button-outlined p-button-sm"
            />
          )}
          className="allTableTDCenterCommon"
        />
      </DataTable>
    </div>
  );
}

export default FilesManagement;
