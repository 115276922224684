import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";

function TestFile() {
  const [dataListClients, setDataListClients] = useState({
    idsOfClients: [],
    namesOfclients: [],
  });
  useEffect(() => {
    async function fetchClientsList() {
      try {
        const response = await fetch("/clientsList");
        const result = await response.json();
        setDataListClients({
          idsOfClients: result.ids,
          namesOfclients: result.names,
        });
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    }
    fetchClientsList();
  }, []);

  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  return (
    <div>
      <Dropdown
        value={selectedCity}
        onChange={(e) => setSelectedCity(e.value)}
        options={dataListClients.namesOfclients.map((name, index) => ({
          label: name,
          value: dataListClients.idsOfClients[index],
        }))}
        // optionLabel="label"
        placeholder="Select a City"
        className="w-full md:w-14rem"
      />
    </div>
  );
}

export default TestFile;
