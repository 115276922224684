import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import { FaRegUserCircle, FaUserAlt, FaPhoneAlt } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdOutlineAlternateEmail, MdSecurity } from "react-icons/md";
import { RiLockPasswordFill, RiRefreshLine } from "react-icons/ri";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import "./LoginPage.css";
import LoginLogo from "../../images/CustomerImages/login911.png";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import Logo from "../../images/logo 911.png";
import { Toast } from "primereact/toast";

function LoginPage() {
  const toast = useRef();
  const stepperRef = useRef(null);
  const jwtTokenn = uuidv4();
  const [userName, setUserName] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const randomString = Math.random().toString(36).slice(8);
  const [captcha, setCaptcha] = useState(randomString);
  const [text, setText] = useState("");
  const [valid, setValid] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [activeMainLink, setActiveMainLink] = useState("mainLink1");
  const [mainComponent1, setMainComponent1] = useState(true);
  const [mainComponent2, setMainComponent2] = useState(false);
  const [mainComponent3, setMainComponent3] = useState(false);
  const [registerForm, setRegisterForm] = useState({
    loginID: "",
    clntName: "",
    emailID: "",
    contactNo: "",
    address: "",
    userPwd: "",
    confirmedPwd: "",
    selectedRole: "",
  });
  const handleEdit = (name, value) => {
    setRegisterForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  function refreshCaptcha() {
    setCaptcha(Math.random().toString(36).slice(8));
  }

  const MatchCaptcha = (event) => {
    setClicked(true);
    event.preventDefault();
    if (text === captcha) {
      setValid(true);
      handleSubmit(event);
      return;
    } else {
      setValid(false);
      refreshCaptcha();
      return;
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch("/authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: userName.toLowerCase(),
          userPassword,
        }),
      });

      if (response.ok) {
        sessionStorage.setItem("loginUserEmail", userName.toLowerCase());
        const data = await response.json();

        const loginTimeResponse = await fetch("/loginTimeUser", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uniqueID: data.user.uniqueID,
            login: new Date(),
          }),
        });

        if (loginTimeResponse.ok) {
          const oldToken = sessionStorage.getItem("jwtToken");

          if (oldToken) {
            const expireToken = await fetch("/expire-token", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: oldToken,
              }),
            });

            if (expireToken.ok) {
              sessionStorage.removeItem("jwtToken");
              sessionStorage.clear();
            } else {
              console.error("Failed to expire token");
            }
          }

          //example code
          // if (sessionStorage.getItem("employeeID")) {
          //   sessionStorage.removeItem("employeeID");
          // }

          sessionStorage.setItem("jwtToken", data.jwtToken);
          sessionStorage.setItem("uniqueID", data.user.uniqueID);
          sessionStorage.setItem("roleName", data.user.role[0].roleName);

          setIsAuthenticated(true);
        } else {
          setError("Failed to save login time");
        }
      } else {
        setError("Incorrect username or password");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/enduser", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        });

        if (response.ok) {
          const roleName = sessionStorage.getItem("roleName");
          const uniqueID = sessionStorage.getItem("uniqueID");
          const jwtToken = sessionStorage.getItem("jwtToken");
          if (roleName === "Client") {
            sessionStorage.setItem("clientUniqueID", uniqueID);
          } else if (roleName === "Admin") {
            sessionStorage.setItem("adminUniqueID", uniqueID);
          } else if (roleName === "User") {
            sessionStorage.setItem("userUniqueID", uniqueID);
          } else {
            navigate(`/error`);
          }

          navigate(`/LoginUser/${jwtTokenn}/`);
        } else {
          console.log("Error: ", response.status);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated, navigate]);

  const handleMainClick = (link) => {
    setActiveMainLink(link);
  };
  const handleMainClic = () => {
    stepperRef.current.nextCallback();
  };

  const handleSubmitApi = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/InsertBulkClientDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            clientName: registerForm.clntName,
            clientEmailID: registerForm.emailID,
            clientAddress: registerForm.address,
            clientContactNumber: registerForm.contactNo,
          },
        ]),
      });

      // Check the response status
      if (response.ok) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved",
          life: 10000,
        });
        setRegisterForm({
          loginID: "",
          clntName: "",
          emailID: "",
          contactNo: "",
          address: "",
          userPwd: "",
          confirmedPwd: "",
          selectedRole: "",
        });
        handleMainClic();
        setOpenConfirmDialog(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Some Error Occured",
          life: 5000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Some Error Occured",
        error,
        life: 5000,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={openConfirmDialog}
        onHide={() => {
          setOpenConfirmDialog(false);
        }}
        style={{ width: "50vw" }}
        className="ConfirmDialog">
        <Stepper ref={stepperRef}>
          <StepperPanel header="Confirm Your Details">
            <div className="grid">
              {/* <div className="col-12 lg:col-6">
                  <div className="confirmImage">
                    <img src={ThankYoU} width={100} className="mt-6 ml-2" />
                  </div>
                </div> */}
              <div className="col-12 lg:col-12">
                <div className="confirmDialogForm">
                  <table width="100%">
                    <thead>
                      <tr>
                        <td>
                          <FaUserAlt /> Clientname
                        </td>
                        <td style={{ color: "#888888" }}>
                          {" "}
                          : {registerForm.clntName}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <MdOutlineAlternateEmail />
                          EmailID
                        </td>
                        <td style={{ color: "#888888" }}>
                          : {registerForm.emailID}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FaPhoneAlt />
                          Contact No
                        </td>
                        <td style={{ color: "#888888" }}>
                          : {registerForm.contactNo}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FaMapLocationDot />
                          Address
                        </td>
                        <td style={{ color: "#888888" }}>
                          : {registerForm.address}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <RiLockPasswordFill />
                          Password
                        </td>
                        <td style={{ color: "#888888" }}>
                          :{" "}
                          <Password
                            value={registerForm.userPwd}
                            onChange={(e) =>
                              handleEdit("userPwd", e.target.value)
                            }
                            toggleMask
                            required
                            className="customInputField"
                            placeholder="Enter Password"
                            readOnly
                          />
                        </td>
                      </tr>
                    </thead>
                  </table>
                  <div className="flex justify-content-between mt-3">
                    <div>
                      <p
                        className=""
                        style={{
                          marginTop: "-1px",
                          marginLeft: "5px",
                        }}>
                        <MdSecurity /> Login Details
                      </p>
                    </div>

                    <div className="ml-3">
                      <p
                        className=""
                        style={{
                          marginTop: "-1px",
                          marginLeft: "5px",
                        }}>
                        <MdOutlineAlternateEmail />
                        <span className="ml-1">
                          EmailId and password mentioned above.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-center">
                    <Button
                      rounded
                      label="Edit or Cancel"
                      className="w-4"
                      outlined
                      severity="warning"
                      onClick={() => {
                        setOpenConfirmDialog(false);
                      }}
                    />
                    <Button
                      type="submit"
                      rounded
                      label="Confirm"
                      severity="success"
                      className="w-4 ml-3"
                      onClick={() => stepperRef.current.nextCallback()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </StepperPanel>
          {/* <StepperPanel header="Verify OTP">
            <div className="flex flex-column h-12rem">
              <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                Content II
              </div>
            </div>
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => stepperRef.current.nextCallback()}
              />
            </div>
          </StepperPanel> */}
          <StepperPanel header="Verification Status">
            <div className="flex flex-column h-12rem">
              <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                Content III
              </div>
            </div>
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => stepperRef.current.nextCallback()}
              />
            </div>
          </StepperPanel>
        </Stepper>
      </Dialog>
      <div className="loginPageParent">
        {/* <nav>
          <Link to="/login">
            <img className="loginNav-icon" src={LoginLogo} alt="" />
          </Link>
        </nav> */}
        <div className="grid">
          <div className="col-12 lg:col-6">
            <Card className="loginLeftSideCard">
              <div className="topImg">
                <img
                  src={Logo}
                  alt="topLogo"
                  height={100}
                  className="logo-Img"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
                <h1>Welcome Back</h1>
              </div>
              <div className="login-left-img">
                <img src={LoginLogo} alt="middleLogo" className="middleLogo" />
              </div>
            </Card>
          </div>
          <div className="col-12 lg:col-6">
            <Card className="loginCardDiv">
              <div
                className="flex"
                style={{
                  backgroundColor: "white",
                  borderRadius: "50px",
                }}>
                <button
                  // className="testButton w-full "
                  className={`testButton w-full ${
                    activeMainLink === "mainLink1" ? "active-link-css" : ""
                  }`}
                  onClick={() => {
                    handleMainClick("mainLink1");
                    setMainComponent1(true);
                    setMainComponent2(false);
                  }}>
                  {" "}
                  Login
                </button>
                <button
                  className={`testButton w-full ${
                    activeMainLink === "mainLink2" ? "active-link-css" : ""
                  }`}
                  onClick={() => {
                    handleMainClick("mainLink2");
                    setMainComponent1(false);
                    setMainComponent2(true);
                  }}>
                  Signup
                </button>
              </div>
              {mainComponent1 && (
                <div className="loginCard ">
                  <form onSubmit={MatchCaptcha}>
                    {/* <form onSubmit={handleSubmit}> */}
                    <table>
                      <tr>
                        <td colSpan={2}>
                          <h4 className="heading-for-login">
                            Login To Continue
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <label htmlFor="UserName" className="grey-color">
                            Username
                            <span className="starForMandatoryInInputFields">
                              {" "}
                              *
                            </span>
                          </label>
                        </td>
                        <td className="relative">
                          <FaRegUserCircle
                            style={{
                              position: "absolute",
                              top: "15px",
                              left: "10px",
                            }}
                            className="svgIconLogin"
                          />
                          <InputText
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            //   keyfilter="email"
                            autoComplete="off"
                            className="customInputField"
                            placeholder="Enter Registered EmailID"
                            required
                            autoFocus
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="UserName" className="grey-color">
                            Password{" "}
                            <span className="starForMandatoryInInputFields">
                              *
                            </span>
                          </label>
                        </td>
                        <td className="relative">
                          <RiLockPasswordFill
                            style={{
                              position: "absolute",
                              top: "15px",
                              left: "10px",
                              zIndex: "2",
                            }}
                            className="gsvgIconLogin"
                            placeholder="Enter Password"
                          />
                          <Password
                            value={userPassword}
                            onChange={(e) => setUserPassword(e.target.value)}
                            toggleMask
                            required
                            className="customInputField"
                            feedback={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ position: "relative" }}
                          className="textCenter">
                          <div
                            style={{
                              marginLeft: "5px",
                              marginRight: "10px",
                              display: "inline-block",
                            }}
                            className="Captcha">
                            {captcha}
                          </div>
                          <RiRefreshLine
                            onClick={refreshCaptcha}
                            style={{
                              fontSize: "1.5rem",
                              position: "absolute",
                              top: "9px",
                              cursor: "pointer",
                              color: "black",
                            }}
                            pointerEvents={valid ? "none" : ""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {clicked && !valid && (
                            <p cl className="invalid-text textCenter">
                              Invalid Captcha
                            </p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <div className="form-control">
                          <InputText
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter Text Above"
                            disabled={valid}
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            required
                          />
                        </div>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <Link to="/forgetPassword" className="text-center">
                            Forgot Password?
                          </Link>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td colSpan={2}>
                          <Button
                            type="submit"
                            loading={isLoading}
                            label="submit"
                            raised
                            title="Fill All The InputFields"
                            disabled={!text || !userName || !userPassword}
                            // disabled={!userName || !userPassword}
                            className="roundad"
                          />
                        </td>
                      </tr>
                    </table>
                  </form>
                  {error && (
                    <p
                      className="text-center text-danger"
                      style={{ height: "20px", color: "red" }}>
                      {error}
                    </p>
                  )}
                </div>
              )}

              {mainComponent2 && (
                <>
                  <h2 className="text-center" style={{ color: "white" }}>
                    Register Now
                  </h2>
                  <div className="loginCard">
                    <div>
                      {/* <form onSubmit={handleSubmit}> */}
                      <div className="grid ipText">
                        <div className="col-12 lg:col-6 p-2">
                          <div className="mb-2">
                            <label htmlFor="UserName" className="grey-color">
                              ClientName
                              <span className="starForMandatoryInInputFields">
                                *
                              </span>
                            </label>
                          </div>
                          <div className="relative">
                            <FaUserAlt
                              style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                              }}
                              className="svgIconLogin"
                            />
                            <InputText
                              value={registerForm.clntName}
                              onChange={(e) =>
                                handleEdit("clntName", e.target.value)
                              }
                              //   keyfilter="email"
                              autoComplete="off"
                              className="customInputField"
                              placeholder="Eg. John or CompanyName"
                              required
                              autoFocus
                            />
                          </div>
                        </div>
                        <div className="col-12 lg:col-6 p-2">
                          <div className="mb-2">
                            <label htmlFor="UserName" className="grey-color">
                              Primary EmailID
                              <span className="starForMandatoryInInputFields">
                                {" "}
                                * As Login ID
                              </span>
                            </label>
                          </div>
                          <div className="relative">
                            <MdOutlineAlternateEmail
                              style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                              }}
                              className="svgIconLogin"
                            />
                            <InputText
                              value={registerForm.emailID}
                              onChange={(e) =>
                                handleEdit("emailID", e.target.value)
                              }
                              keyfilter="email"
                              autoComplete="off"
                              className="customInputField"
                              placeholder="Enter Professional EmailID"
                              required
                              autoFocus
                            />
                          </div>
                        </div>
                      </div>{" "}
                      <div className="grid">
                        <div className="col-12 lg:col-6 p-2">
                          <div className="mb-2">
                            <label htmlFor="UserName" className="grey-color">
                              Contact No
                              <span className="starForMandatoryInInputFields">
                                {" "}
                                *
                              </span>
                            </label>
                          </div>
                          <div className="relative">
                            <FaPhoneAlt
                              style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                              }}
                              className="svgIconLogin"
                            />
                            <InputText
                              value={registerForm.contactNo}
                              onChange={(e) =>
                                handleEdit("contactNo", e.target.value)
                              }
                              //   keyfilter="email"
                              autoComplete="off"
                              className="customInputField"
                              placeholder="Enter Contact No"
                              required
                              autoFocus
                            />
                          </div>
                        </div>
                        <div className="col-12 lg:col-6 p-2">
                          <div className="mb-2">
                            <label htmlFor="UserName" className="grey-color">
                              Address
                              <span className="starForMandatoryInInputFields">
                                {" "}
                                *
                              </span>
                            </label>
                          </div>
                          <div className="relative">
                            <FaMapLocationDot
                              style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                              }}
                              className="svgIconLogin"
                            />
                            <InputText
                              value={registerForm.address}
                              onChange={(e) =>
                                handleEdit("address", e.target.value)
                              }
                              //   keyfilter="email"
                              autoComplete="off"
                              className="customInputField"
                              placeholder="1-1, Area, State, Country, Pincode"
                              required
                              autoFocus
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid">
                        <div className="col-12 lg:col-6 p-2">
                          <div className="mb-2">
                            <label htmlFor="UserName" className="grey-color">
                              Enter Password
                              <span className="starForMandatoryInInputFields">
                                *
                              </span>
                            </label>
                          </div>
                          <div className="relative">
                            <RiLockPasswordFill
                              style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                                zIndex: "2",
                              }}
                              className="gsvgIconLogin"
                            />
                            <Password
                              value={registerForm.userPwd}
                              onChange={(e) =>
                                handleEdit("userPwd", e.target.value)
                              }
                              toggleMask
                              required
                              className="customInputField"
                              placeholder="Enter Password"
                            />
                          </div>
                        </div>
                        <div className="col-12 lg:col-6 p-2">
                          <div className="mb-2">
                            <label htmlFor="UserName" className="grey-color">
                              Confirm Password
                              <span className="starForMandatoryInInputFields">
                                *
                              </span>
                            </label>
                          </div>
                          <div className="relative">
                            <RiLockPasswordFill
                              style={{
                                position: "absolute",
                                top: "15px",
                                left: "10px",
                                zIndex: "2",
                              }}
                              className="gsvgIconLogin"
                            />
                            <Password
                              value={registerForm.confirmedPwd}
                              onChange={(e) =>
                                handleEdit("confirmedPwd", e.target.value)
                              }
                              toggleMask
                              required
                              className="customInputField"
                              placeholder="Confirm Password"
                            />
                          </div>
                        </div>
                      </div>
                      {registerForm.confirmedPwd !== registerForm.userPwd && (
                        <p
                          className="text-center text-danger"
                          style={{ height: "20px", color: "red" }}>
                          Password Not Matched
                        </p>
                      )}
                      <Button
                        label="submit"
                        className="mt-1"
                        disabled={
                          !registerForm.clntName ||
                          !registerForm.emailID ||
                          !registerForm.contactNo ||
                          !registerForm.address ||
                          !registerForm.userPwd ||
                          !registerForm.confirmedPwd ||
                          registerForm.userPwd !== registerForm.confirmedPwd
                        }
                        onClick={() => {
                          setOpenConfirmDialog(true);
                        }}></Button>
                    </div>
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
