import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdBusinessCenter } from "react-icons/md";
import { IoCallSharp } from "react-icons/io5";
import { GiReceiveMoney } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { Card } from "primereact/card";
import { Tag } from "primereact/tag";
import { MdMarkEmailUnread } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { Button } from "primereact/button";

function Dashboard() {
  const role = sessionStorage.getItem("roleName");
  const [allCallDetails, setAllCallDetails] = useState([]);
  const [allClientIncidents, setAllClientIncidents] = useState([]);

  async function fetchAllCallDetails() {
    const response = await fetch("/allCalls", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllCallDetails(result);
    } else {
      setAllCallDetails([]);
    }
  }

  async function fetchAllClientIncidents() {
    const response = await fetch("/distinctIncidents", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllClientIncidents(result);
    } else {
      setAllClientIncidents([]);
    }
  }

  useEffect(() => {
    fetchAllCallDetails();
    fetchAllClientIncidents();
  }, []);

  const statusBodyTemplate = (rowData) => {
    const statusSeverity = {
      Applied: "info",
      "In-Progress": "warning",
      Pending: "danger",
      Completed: "success",
    };

    return (
      <Tag
        value={rowData.status}
        severity={statusSeverity[rowData.status] || "info"}
      />
    );
  };

  const actionCallsContact = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon={<MdMarkEmailUnread />}
          text
          title="edit"
          // onClick={() => handleRowClick(rowData)}
          // className="roundad"
        >
          <a href="mailto:support@clotatechnologu.com">
            {rowData.EmailAddress}
          </a>
        </Button>
        <Button
          icon={<IoMdCall />}
          text
          title="edit"
          rounded
          // className="roundad"
        >
          {" "}
          <a href="tel:+919876543210">{rowData.mobileNumber}</a>
        </Button>
      </div>
    );
  };
  return (
    <div className="parentDivDashboard">
      {role === "Admin" && (
        <>
          <div className="grid m-2">
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">
                      Clients
                    </span>
                    <div className="text-900 font-medium text-xl">1</div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{ width: "2.5rem", height: "2.5rem" }}>
                    <MdBusinessCenter />
                  </div>
                </div>
                {/* <span className="text-green-500 font-medium">24 new </span>
              <span className="text-500">since last visit</span> */}
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">
                      Calls
                    </span>
                    <div className="text-900 font-medium text-xl">10</div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-orange-100 border-round"
                    style={{ width: "2.5rem", height: "2.5rem" }}>
                    <IoCallSharp />
                  </div>
                </div>
                {/* <span className="text-green-500 font-medium">%52+ </span>
              <span className="text-500">since last week</span> */}
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-bold mb-3">
                      Revenue
                    </span>
                    <div className="text-900 font-medium text-xl">$0</div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                    style={{ width: "2.5rem", height: "2.5rem" }}>
                    <GiReceiveMoney />
                  </div>
                </div>
                {/* <span className="text-green-500 font-medium">520 </span>
              <span className="text-500">newly registered</span> */}
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">
                      Users
                    </span>
                    <div className="text-900 font-medium text-xl">10</div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-purple-100 border-round"
                    style={{ width: "2.5rem", height: "2.5rem" }}>
                    <FaUsers />
                  </div>
                </div>
                {/* <span className="text-green-500 font-medium">85 </span>
              <span className="text-500">responded</span> */}
              </div>
            </div>
          </div>

          <div className="grid m-2">
            <div className="col-12 lg:col-6">
              <Card className="">
                <h3 className="text-start">Recent Calls</h3>
                <DataTable
                  // tableStyle={{ minWidth: "50rem" }}
                  value={allCallDetails}
                  size="small"
                  paginator
                  rows={10}
                  showGridlines
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  removableSort
                  scrollable
                  scrollHeight="250px"
                  className="FilterTable allDataTableThCenterCommon m-2"
                  emptyMessage="No Data found.">
                  <Column
                    header="ClientName"
                    field="fullName"
                    frozen
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="CallDateTime"
                    field="CallDateTime"
                    className="allTableTDCenterCommon"
                    sortable
                  />
                  <Column
                    header="Category"
                    field="callCategory"
                    className="allTableTDCenterCommon"
                    sortable
                  />
                  <Column
                    header="TimeZone"
                    field="timeZone"
                    className="allTableTDCenterCommon"
                  />

                  <Column
                    header="Message"
                    field="message"
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="Action"
                    className="allTableTDCenterCommon"
                    body={actionCallsContact}></Column>
                </DataTable>
              </Card>
            </div>
            <div className="col-12 lg:col-6">
              <Card>
                <h3 className="text-start">Recent Incidents</h3>
                <DataTable
                  // tableStyle={{ minWidth: "50rem" }}
                  value={allClientIncidents}
                  size="small"
                  paginator
                  rows={10}
                  showGridlines
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  removableSort
                  scrollable
                  scrollHeight="350px"
                  className="FilterTable allDataTableThCenterCommon m-2"
                  emptyMessage="No Data found.">
                  <Column
                    header="IncidentID"
                    field="caseID"
                    frozen
                    className="allTableTDCenterCommon"
                    // body={handleRowClick}
                  />
                  <Column
                    header="Client"
                    field="clientName"
                    className="allTableTDCenterCommon"
                    sortable
                  />
                  <Column
                    header="Description	"
                    field="description"
                    className="allTableTDCenterCommon"
                    sortable
                  />

                  <Column
                    header="Status"
                    field="status"
                    className="allTableTDCenterCommon"
                    sortable
                    body={statusBodyTemplate}
                  />
                  <Column
                    header="DueDate"
                    field="DueDate"
                    sortable
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="Action"
                    className="allTableTDCenterCommon"
                    body={actionCallsContact}></Column>
                </DataTable>
              </Card>
            </div>
          </div>
        </>
      )}
      {role === "User" && (
        <div className="userDivDashboard">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut,
          excepturi?
        </div>
      )}
      {role === "Client" && (
        <>
          <div className="grid m-5">
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">
                      My Calls
                    </span>
                    <div className="text-900 font-medium text-xl">1</div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style={{ width: "2.5rem", height: "2.5rem" }}>
                    <IoCallSharp />
                  </div>
                </div>
                {/* <span className="text-green-500 font-medium">24 new </span>
              <span className="text-500">since last visit</span> */}
              </div>
            </div>
            <div className="col-12 md:col-6 lg:col-3">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-500 font-medium mb-3">
                      My Incidents
                    </span>
                    <div className="text-900 font-medium text-xl">10</div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-orange-100 border-round"
                    style={{ width: "2.5rem", height: "2.5rem" }}>
                    <MdBusinessCenter />
                  </div>
                </div>
                {/* <span className="text-green-500 font-medium">%52+ </span>
              <span className="text-500">since last week</span> */}
              </div>
            </div>
          </div>

          <div className="grid">
            <div className="col-6 lg:col-6">
              <Card className="m-2">
                <h3 className="text-start">My Calls</h3>
                <DataTable
                  tableStyle={{ minWidth: "50rem" }}
                  value={allCallDetails}
                  size="small"
                  paginator
                  rows={10}
                  showGridlines
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  removableSort
                  scrollable
                  scrollHeight="250px"
                  className="FilterTable allDataTableThCenterCommon m-2"
                  emptyMessage="No Data found.">
                  <Column
                    header="ClientName"
                    field="fullName"
                    frozen
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="EmailID"
                    field="emailAddress"
                    className="allTableTDCenterCommon"
                    sortable
                  />
                  <Column
                    header="ContactNo"
                    field="mobileNumber"
                    className="allTableTDCenterCommon"
                    sortable
                  />
                  <Column
                    header="Category"
                    field="callCategory"
                    className="allTableTDCenterCommon"
                    sortable
                  />
                  <Column
                    header="CallDateTime"
                    field="CallDateTime"
                    className="allTableTDCenterCommon"
                    sortable
                  />
                  <Column
                    header="TimeZone"
                    field="timeZone"
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="Amount"
                    field="amount"
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="Message"
                    field="message"
                    className="allTableTDCenterCommon"
                  />
                </DataTable>
              </Card>
            </div>
            <div className="col-6 lg:col-6">
              <Card style={{ height: "340px" }}>
                <h3 className="text-start">Recent Incidents</h3>
                <DataTable
                  tableStyle={{ minWidth: "50rem" }}
                  value={allClientIncidents}
                  size="small"
                  paginator
                  rows={10}
                  showGridlines
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  removableSort
                  scrollable
                  scrollHeight="350px"
                  className="FilterTable allDataTableThCenterCommon m-2"
                  emptyMessage="No Data found.">
                  <Column
                    header="IncidentID"
                    field="caseID"
                    frozen
                    className="allTableTDCenterCommon"
                    // body={handleRowClick}
                  />

                  <Column
                    header="Description	"
                    field="description"
                    className="allTableTDCenterCommon"
                    sortable
                  />
                  <Column
                    header="Category"
                    field="categoryName"
                    className="allTableTDCenterCommon"
                    sortable
                  />
                  <Column
                    header="Status"
                    field="status"
                    className="allTableTDCenterCommon"
                    sortable
                    body={statusBodyTemplate}
                  />
                  <Column
                    header="DueDate"
                    field="DueDate"
                    sortable
                    className="allTableTDCenterCommon"
                  />
                </DataTable>
              </Card>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Dashboard;
