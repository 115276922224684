import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { TabPanel, TabView } from "primereact/tabview";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import AddServiceCategoryImg from "../../../images/serviceCategory.png";
import "./IncidentsCssFile.css";

import MonitorIncidents from "./MonitorIncidents";
import FilesManagement from "./FilesManagement";
import { Dropdown } from "primereact/dropdown";

function ManageIncidents() {
  const toast = useRef();

  const [openServiceDialog, setOpenServiceDialog] = useState(false);
  const [viewTechCategory, setViewTechCategory] = useState("OpenAllCategory");
  const [isLoading, setIsLoading] = useState(false);

  const [allServiceCategory, setAllServiceCategory] = useState([]);
  const [dataListCategory, setDataListCategory] = useState({
    idsCategory: [],
    namesCategory: [],
  });

  const [formIncidents, setFormIncidents] = useState({
    apiID: "",
    caseID: "",
    clientID: "",
    categoryID: "",
    description: "",
    status: "",
    comments: "",
    assigntedTo: "",
    caseOwner: "",
    dueDate: "",
  });
  const [dataListClients, setDataListClients] = useState({
    idsOfClients: [],
    namesOfclients: [],
  });
  const [dataListUsers, setDataListUsers] = useState({
    idsUsers: [],
    namesUsers: [],
  });
  const [allTechLists, setAllTechLists] = useState({
    displayName: [],
  });
  const [suggestions, setSuggestions] = useState([]);
  const [technology, setTechnology] = useState("MSSQL");
  const [allTechSrvceCategory, setAllTechSrvceCategory] = useState("");

  const [formData, setFormData] = useState({
    categoryName: "",
    description: "",
    technology: "",
  });

  // List of database names
  const databases = [
    "MSSQL",
    "MySQL",
    "Oracle",
    "PostgreSQL",
    "SQLite",
    "MongoDB",
    "MariaDB",
    "Cassandra",
    "Redis",
    "Elasticsearch",
  ];

  const searchDatabases = (event) => {
    let filteredDatabases = [];
    if (event.query.length === 0) {
      filteredDatabases = [];
    } else {
      filteredDatabases = databases.filter((db) =>
        db.toLowerCase().includes(event.query.toLowerCase())
      );
    }
    setSuggestions(filteredDatabases);
  };

  async function fetchallServiceCategory() {
    const response = await fetch("/allServiceCategories", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllServiceCategory(result);
    } else {
      setAllServiceCategory([]);
    }
  }
  async function fetchAllTechList() {
    const response = await fetch("/allTechnologies", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    setAllTechLists({
      displayName: result.displayName,
    });
  }

  async function fetchAllTechIncidents() {
    const response = await fetch(`/allServiceCategoryByTech/${technology}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllTechSrvceCategory(result);
    } else {
      setAllTechSrvceCategory([]);
    }
  }

  useEffect(() => {
    async function fetchCategoriesList() {
      const response = await fetch("/allCategoriesList", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
      });
      const result = await response.json();
      setDataListCategory({
        idsCategory: result.ids,
        namesCategory: result.names,
      });
    }

    fetchCategoriesList();
    fetchallServiceCategory();
    fetchAllTechList();
    fetchAllTechIncidents();
  }, []);

  const crearVariales = () => {
    setFormData({
      categoryName: "",
      description: "",
      technology: "",
    });
  };

  const handleInputChange = (name, value) => {
    setFormIncidents((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        keyfilter={/^[a-zA-Z ]*$/}
        value={options.value}
        onChange={(e) =>
          options.editorCallback(e.target.value.substring(0, 150))
        }
      />
    );
  };
  const onRowEditServiceCategory = async (e) => {
    let _tasks = [...allServiceCategory];
    let { newData, index } = e;

    _tasks[index] = newData;

    setAllServiceCategory(_tasks);

    try {
      const response = await fetch("/bulkUpdateServiceCategory", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([newData]),
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Updated successfully!",
          life: 3000,
        });
        // triggerApiDataChange();

        //  setError("");
      }

      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update data!",
          life: 3000,
        });
        // triggerApiDataChange();
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSubmitServiceCategory = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch("/InsertBulkServiceCategory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            categoryName: formData.categoryName,
            description: formData.description,
            technology: formData.technology,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved Successfully!",
          life: 3000,
        });
        crearVariales();
        setOpenServiceDialog(false);
        fetchallServiceCategory();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating Details.",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating Holiday.",
        life: 3000,
      });
    }

    setIsLoading(false);
  };

  return (
    <div>
      <Toast ref={toast} />
      <Card className="m-5">
        <Dialog
          visible={openServiceDialog}
          onHide={() => {
            setOpenServiceDialog(false);
            crearVariales();
          }}
          style={{ width: "50vw" }}
          className="dbaAllDialogs">
          <h3 className="text-center">Add Service Category</h3>
          <div className="grid">
            <div className="col-12  lg:col-6">
              <div className="addClientLeftImg">
                <img src={AddServiceCategoryImg} alt="" width="100%" />
              </div>
            </div>
            <div className="col-12 lg:col-6">
              <form onSubmit={handleSubmitServiceCategory}>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Holiday Name" className="font-bold block">
                    Category Name
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formData.categoryName}
                    // keyfilter={/^[a-zA-Z\s]+$/}
                    onChange={(e) => {
                      handleInputChange(
                        "categoryName",
                        e.target.value.substring(0, 25)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    Description
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    value={formData.description}
                    keyfilter={/^[a-zA-Z\s]+$/}
                    // className="datemobileFile"
                    onChange={(e) => {
                      handleInputChange(
                        "description",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Day" className="font-bold block">
                    Technology
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <AutoComplete
                    value={formData.technology}
                    keyfilter={/^[a-zA-Z\s]+$/}
                    suggestions={suggestions}
                    completeMethod={searchDatabases}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        technology: e.value,
                      });
                    }}
                    onSelect={(e) => {
                      setFormData({
                        ...formData,
                        technology: e.value,
                      });
                    }}
                    className="w-full"
                    placeholder="Eg: MSSQL."
                  />
                </div>

                <div className="flex justify-content-center mt-3">
                  <Button
                    type="submit"
                    label="Submit"
                    loading={isLoading}
                    disabled={
                      !formData.categoryName ||
                      !formData.description ||
                      !formData.technology
                    }
                    rounded
                    raised
                    className="w-full roundad"
                  />
                </div>
              </form>
            </div>
          </div>
        </Dialog>

        <TabView scrollable>
          <TabPanel header="Incident Category">
            <div className="flex justify-content-between">
              <div>
                <Button
                  text
                  label="All"
                  security="secondary"
                  onClick={() => {
                    setViewTechCategory("OpenAllCategory");
                  }}
                />
                <span>Technology:</span>
                <Dropdown
                  value={technology}
                  options={allTechLists.displayName.map((name, index) => ({
                    label: name,
                    value: allTechLists.displayName[index],
                  }))}
                  onChange={(e) => {
                    setTechnology(e.value);
                    setViewTechCategory("openTechCategory");
                  }}
                  placeholder="Select Technology"
                  // className="w-full"
                  checkmark={true}
                  highlightOnSelect={true}
                />
              </div>
              <Button
                label="Add"
                rounded
                onClick={() => {
                  setOpenServiceDialog(true);
                }}
              />
            </div>
            {viewTechCategory === "OpenAllCategory" && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allServiceCategory}
                size="small"
                paginator
                editMode="row"
                dataKey="clientID"
                onRowEditComplete={onRowEditServiceCategory}
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column
                  header="CategoryID"
                  field="categoryID"
                  frozen
                  filter
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="CategoryName"
                  field="categoryName"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditor(options)}></Column>
                <Column
                  header="Description"
                  field="description"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditor(options)}></Column>
                <Column
                  header="Technology"
                  field="technology"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditor(options)}></Column>
                <Column rowEditor></Column>
              </DataTable>
            )}

            {viewTechCategory === "openTechCategory" && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allTechSrvceCategory}
                size="small"
                paginator
                editMode="row"
                dataKey="clientID"
                onRowEditComplete={onRowEditServiceCategory}
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column
                  header="CategoryID"
                  field="categoryID"
                  frozen
                  filter
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="CategoryName"
                  field="categoryName"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditor(options)}></Column>
                <Column
                  header="Description"
                  field="description"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditor(options)}></Column>
                <Column
                  header="Technology"
                  field="technology"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditor(options)}></Column>
                <Column rowEditor></Column>
              </DataTable>
            )}
          </TabPanel>
          <TabPanel header="Incidents">
            <MonitorIncidents />
          </TabPanel>
          <TabPanel header="Manage Files">
            <FilesManagement />
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}

export default ManageIncidents;
