import { Button } from "primereact/button";
import React, { useState, useEffect, useRef } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import "./Pricing.css";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { SiTicktick } from "react-icons/si";
import { FaDollarSign, FaIndianRupeeSign } from "react-icons/fa6";

function PricingPage() {
  const toast = useRef(null);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/CheckoutAndPayment");
  };

  const handleNavigateFreeCall = () => {
    navigate("/ScheduleAFreeCall");
  };
  // <FaIndianRupeeSign /> 10,078

  return (
    <>
      <div className="homePricing displyToastTop">
        <Toast ref={toast} />

        <div className="surface-0">
          <div className="text-900 font-bold text-6xl mb-4 text-center Text-Gradient">
            Our Plans
          </div>
          <div className="text-700 text-xl mb-6 text-center line-height-3">
            SQL Server Packages To Meet Any Budget.
          </div>

          <div className="grid">
            <div className="col-12 lg:col-4">
              <div className="p-4 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Free</div>
                  <div className="text-600 font-bold">30 Minute Call</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />

                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        Free Consultaion
                      </span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        SQL Server Audit
                      </span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        Live Troubleshooting Support.
                      </span>
                    </li>{" "}
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        Experts Help
                      </span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                  <Button
                    label="Schedule Call"
                    className="p-2.4 w-full mt-auto Text-Gradientt"
                    onClick={handleNavigateFreeCall}
                    rounded
                  />
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-4 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">
                    Standard
                  </div>
                  <div className="text-600">Plan description</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">$ 119</span>
                    <span className=" font-medium text-600 ml-2">per hour</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        Proactive monitoring and response
                      </span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        Improve performance
                      </span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        Monthly improvements
                      </span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        Ongoing performance improvements
                      </span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <Button
                    label="Schedule Call"
                    onClick={handleNavigate}
                    className="p-2.4 w-full Text-Gradientt"
                    rounded
                  />
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-4 h-full">
                <div
                  className="shadow-2 p-3 flex flex-column"
                  style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">
                    Premium
                  </div>
                  <div className="text-600">Plan description</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className=" font-medium text-600 ml-2">Ask Price</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        Comprensive SQL Audits
                      </span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        SQL Server consolidation
                      </span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        Infrastructure spend optimization
                      </span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        <SiTicktick className="text-green-500 mr-2" />
                        Migrate and upgrade SQL Servers
                      </span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        {" "}
                        <SiTicktick className="text-green-500 mr-2" />
                        Dedicated Emergency Support
                      </span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>
                        {" "}
                        <SiTicktick className="text-green-500 mr-2" />
                        Expert DBA help
                      </span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  {/* <Button
                  label="Buy Now"
                  className="p-3 w-full p-button-outlined"
                              /> */}
                  <Button
                    label="Ask Price"
                    className="p-2.4 w-full  Text-Gradientt"
                    rounded
                    raised
                    onClick={() => {
                      navigate("/ScheduleAPremiumCall");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingPage;
