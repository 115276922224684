import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TiUserAdd } from "react-icons/ti";
import { RiLoginBoxFill, RiUserAddFill } from "react-icons/ri";
import { BiUserCheck } from "react-icons/bi";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { CgAddR } from "react-icons/cg";
import { Dialog } from "primereact/dialog";
import AddServiceCategoryImg from "../../../images/serviceCategory.png";

function IncidentCategories() {
  const toast = useRef();
  const [incidentOwners, setIncidentOwners] = useState([]);
  const [allServiceCategory, setAllServiceCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openIncidentOwners, setOpenIncidentOwners] = useState(false);
  const [openAllOwners, setOpenAllOwners] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [addIncidentOwners, setAddIncidentOwners] = useState(false);
  const [openServiceDialog, setOpenServiceDialog] = useState(false);
  const [openAddssigning, setopenAddssigning] = useState(false);
  const [fullName, setFullName] = useState("");
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    categoryName: "",
    description: "",
    technology: "",
  });
  const crearVariales = () => {
    setFormData({
      categoryName: "",
      description: "",
      technology: "",
    });
  };

  async function fetchIncidentOwners() {
    const response = await fetch("/allIncidentOwners", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setIncidentOwners(result);
    } else {
      setIncidentOwners([]);
    }
  }

  async function fetchallServiceCategory() {
    const response = await fetch("/allServiceCategories", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllServiceCategory(result);
    } else {
      setAllServiceCategory([]);
    }
  }

  useEffect(() => {
    fetchIncidentOwners();
    fetchallServiceCategory();
  }, []);

  const addIncidentsOwners = () => {
    setAddIncidentOwners(true);
  };
  const addServiceCategory = () => {
    setAddIncidentOwners(false);
    setOpenServiceDialog(true);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        keyfilter={/^[a-zA-Z ]*$/}
        value={options.value}
        onChange={(e) =>
          options.editorCallback(e.target.value.substring(0, 150))
        }
      />
    );
  };

  const onRowEditIncidentOwners = async (e) => {
    let _tasks = [...incidentOwners];
    let { newData, index } = e;

    _tasks[index] = newData;

    setIncidentOwners(_tasks);

    try {
      const response = await fetch("/bulkUpdateIncidentOwners", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([newData]),
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Updated successfully!",
          life: 3000,
        });
        // triggerApiDataChange();
        fetchIncidentOwners();
        //  setError("");
      }

      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update data!",
          life: 3000,
        });
        // triggerApiDataChange();
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const onRowEditServiceCategory = async (e) => {
    let _tasks = [...allServiceCategory];
    let { newData, index } = e;

    _tasks[index] = newData;

    setAllServiceCategory(_tasks);

    try {
      const response = await fetch("/bulkUpdateServiceCategory", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([newData]),
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Updated successfully!",
          life: 3000,
        });
        // triggerApiDataChange();
        fetchIncidentOwners();
        //  setError("");
      }

      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update data!",
          life: 3000,
        });
        // triggerApiDataChange();
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSubmitAddIncidentOwners = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch("/InsertBulkIncidentOwners", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            fullName: fullName,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved Successfully!",
          life: 3000,
        });
        setFullName("");
        setAddIncidentOwners(false);
        fetchIncidentOwners();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating Details.",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating Holiday.",
        life: 3000,
      });
    }

    setIsLoading(false);
  };

  const handleSubmitServiceCategory = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch("/InsertBulkServiceCategory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            categoryName: formData.categoryName,
            description: formData.description,
            technology: formData.technology,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved Successfully!",
          life: 3000,
        });
        crearVariales();
        setOpenServiceDialog(false);
        setAddIncidentOwners(false);
        fetchallServiceCategory();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating Details.",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating Holiday.",
        life: 3000,
      });
    }

    setIsLoading(false);
  };

  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const addAssigning = () => {
    setAddIncidentOwners(false);
    setOpenServiceDialog(false);
    setopenAddssigning(true);
  };

  const cities = [
    {
      name: "IncidentOwner",
      code: "IO",
      onclick: addIncidentsOwners,
      icon: <TiUserAdd />,
    },
    {
      name: "Service Category",
      code: "SC",
      onclick: addServiceCategory,
      icon: <RiLoginBoxFill />,
    },
    {
      name: "Add Assigning",
      code: "SC",
      onclick: addAssigning,
      icon: <BiUserCheck />,
    },
  ];

  const dropDownIcons = (option) => {
    return (
      <div className="flex align-items-center">
        <div>
          {option.icon} {option.name}
        </div>
      </div>
    );
  };

  const placeholderTemplate = () => {
    return (
      <div className="p-clearfix" style={{ color: "white" }}>
        <CgAddR style={{ marginRight: "5px", fontSize: "1.2em" }} />
        <span style={{ fontSize: "1em", fontWeight: "bold", color: "white" }}>
          Add
        </span>
      </div>
    );
  };
  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={addIncidentOwners}
        onHide={() => {
          setAddIncidentOwners(false);
          setFullName("");
        }}
        className="dbaAllDialogs">
        <Card header="">
          <h3 className="text-center">Add Incident Owners</h3>
          <form onSubmit={handleSubmitAddIncidentOwners}>
            <label htmlFor="Holiday Name" className="font-bold block">
              Full Name
              <span className="starForMandatoryInInputFields">*</span>
            </label>
            <InputText
              aria-describedby="username-help"
              value={fullName}
              keyfilter={/^[a-zA-Z\s]+$/}
              onChange={(e) => {
                setFullName(e.target.value.substring(0, 25));
              }}
            />
            <div>
              <Button
                type="submit"
                label="Submit"
                loading={isLoading}
                disabled={!fullName}
                rounded
                raised
                className="roundad w-full mt-3 mb-2"
              />
            </div>
          </form>
        </Card>
      </Dialog>
      <Dialog
        visible={openServiceDialog}
        onHide={() => {
          setOpenServiceDialog(false);
          crearVariales();
        }}
        style={{ width: "50vw" }}
        className="dbaAllDialogs">
        <Card className="shadow-5 AddHolidayyform">
          <h3 className="text-center">Add Service Category</h3>
          <div className="grid">
            <div className="col-12  lg:col-6">
              <div className="addClientLeftImg">
                <img src={AddServiceCategoryImg} alt="" width="100%" />
              </div>
            </div>
            <div className="col-12 lg:col-6">
              <form onSubmit={handleSubmitServiceCategory}>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Holiday Name" className="font-bold block">
                    Category Name
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formData.categoryName}
                    keyfilter={/^[a-zA-Z\s]+$/}
                    onChange={(e) => {
                      handleInputChange(
                        "categoryName",
                        e.target.value.substring(0, 25)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    Description
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    value={formData.description}
                    keyfilter={/^[a-zA-Z\s]+$/}
                    // className="datemobileFile"
                    onChange={(e) => {
                      handleInputChange(
                        "description",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Day" className="font-bold block">
                    Technology
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    keyfilter={/^[a-zA-Z\s]+$/}
                    value={formData.technology}
                    onChange={(e) => {
                      handleInputChange(
                        "technology",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>

                <div className="flex justify-content-center mt-3">
                  <Button
                    type="submit"
                    label="Submit"
                    loading={isLoading}
                    disabled={
                      !formData.categoryName ||
                      !formData.description ||
                      !formData.technology
                    }
                    rounded
                    raised
                    className="w-full roundad"
                  />
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Dialog>
      <Dialog
        visible={openAddssigning}
        onHide={() => {
          setopenAddssigning(false);
          crearVariales();
        }}
        style={{ width: "50vw" }}
        className="dbaAllDialogs">
        <Card className="shadow-5 AddHolidayyform">
          <h3 className="text-center">Add Assigning</h3>
          <div className="grid">
            <div className="col-12  lg:col-6">
              <div className="addClientLeftImg">
                <img src={AddServiceCategoryImg} alt="" width="100%" />
              </div>
            </div>
            <div className="col-12 lg:col-6">
              <form onSubmit={handleSubmitServiceCategory}>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Holiday Name" className="font-bold block">
                    CaseID
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    value={formData.categoryName}
                    keyfilter={/^[a-zA-Z\s]+$/}
                    onChange={(e) => {
                      handleInputChange(
                        "categoryName",
                        e.target.value.substring(0, 25)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-wrap gap-3 p-fluid">
                  <label htmlFor="buttondisplay" className="font-bold block">
                    IncidentOwner
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>

                  <InputText
                    aria-describedby="username-help"
                    value={formData.description}
                    keyfilter={/^[a-zA-Z\s]+$/}
                    // className="datemobileFile"
                    onChange={(e) => {
                      handleInputChange(
                        "description",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>
                <div className="flex flex-column gap-2 mb-3">
                  <label htmlFor="Day" className="font-bold block">
                    AssignedTo
                    <span className="starForMandatoryInInputFields">*</span>
                  </label>
                  <InputText
                    aria-describedby="username-help"
                    keyfilter={/^[a-zA-Z\s]+$/}
                    value={formData.technology}
                    onChange={(e) => {
                      handleInputChange(
                        "technology",
                        e.target.value.substring(0, 150)
                      );
                    }}
                  />
                </div>

                <div className="flex justify-content-center mt-3">
                  <Button
                    type="submit"
                    label="Submit"
                    loading={isLoading}
                    disabled={
                      !formData.categoryName ||
                      !formData.description ||
                      !formData.technology
                    }
                    rounded
                    raised
                    className="w-full roundad"
                  />
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Dialog>
      <Card className="m-4">
        <div className="flex justify-content-between">
          <h3>Manage Service Categories</h3>
          <div>
            <Dropdown
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.value);
                if (e.value && e.value.onclick) {
                  e.value.onclick();
                }
              }}
              options={cities}
              optionLabel="name"
              placeholder={placeholderTemplate()}
              className="w-full"
              itemTemplate={dropDownIcons}
              style={{ backgroundColor: "#0055cc", color: "white" }}
            />
          </div>
        </div>
        <TabView>
          <TabPanel header="Incident Owners" leftIcon="pi pi-calendar mr-2">
            <div className="flex">
              <Button
                label="All"
                rounded
                text
                onClick={() => {
                  setOpenIncidentOwners(false);
                  setOpenAllOwners(true);
                }}
              />
              <Button
                label="Assigned"
                rounded
                text
                onClick={() => {
                  setOpenIncidentOwners(true);
                  setOpenAllOwners(false);
                }}
              />
            </div>
            {openAllOwners && (
              <>
                <DataTable
                  tableStyle={{ minWidth: "50rem" }}
                  value={incidentOwners}
                  size="small"
                  paginator
                  rows={10}
                  showGridlines
                  editMode="row"
                  dataKey="assignID"
                  onRowEditComplete={onRowEditIncidentOwners}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  removableSort
                  scrollable
                  scrollHeight="250px"
                  className="FilterTable allDataTableThCenterCommon m-2"
                  emptyMessage="No Data found.">
                  <Column
                    header="ID"
                    field="assignID"
                    frozen
                    filter
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="Full Name"
                    field="fullName"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                    editor={(options) => textEditor(options)}></Column>
                  <Column rowEditor></Column>
                </DataTable>
              </>
            )}{" "}
            {openIncidentOwners && (
              <>
                <DataTable
                  tableStyle={{ minWidth: "50rem" }}
                  // value={clientDetails}
                  size="small"
                  paginator
                  rows={10}
                  showGridlines
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  removableSort
                  scrollable
                  scrollHeight="250px"
                  className="FilterTable allDataTableThCenterCommon m-2"
                  emptyMessage="No Data found.">
                  <Column
                    header="CaseID"
                    field="fullName"
                    frozen
                    filter
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="IncidentOwner"
                    field="ClientName"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                  <Column
                    header="AssignedTo"
                    field="ClientName"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                </DataTable>
              </>
            )}
          </TabPanel>

          <TabPanel header="Service Category" leftIcon="pi pi-calendar mr-2">
            <DataTable
              tableStyle={{ minWidth: "50rem" }}
              value={allServiceCategory}
              size="small"
              paginator
              editMode="row"
              dataKey="clientID"
              onRowEditComplete={onRowEditServiceCategory}
              rows={10}
              showGridlines
              rowsPerPageOptions={[10, 25, 50, 100]}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              removableSort
              scrollable
              scrollHeight="250px"
              className="FilterTable allDataTableThCenterCommon m-2"
              emptyMessage="No Data found.">
              <Column
                header="CategoryID"
                field="categoryID"
                frozen
                filter
                className="allTableTDCenterCommon"
              />
              <Column
                header="CategoryName"
                field="categoryName"
                className="allTableTDCenterCommon"
                sortable
                filter
                editor={(options) => textEditor(options)}></Column>
              <Column
                header="Description"
                field="description"
                className="allTableTDCenterCommon"
                sortable
                filter
                editor={(options) => textEditor(options)}></Column>
              <Column
                header="Technology"
                field="technology"
                className="allTableTDCenterCommon"
                sortable
                filter
                editor={(options) => textEditor(options)}></Column>
              <Column rowEditor></Column>
            </DataTable>
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}

export default IncidentCategories;
