import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addEventListeners, removeEventListeners } from "./EventListeners";
import TimeOutPopUp from "./TimeoutPopUp";

export const TimeoutLogic = () => {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [id, setId] = useState("");
  const uniqueID = sessionStorage.getItem("uniqueID");
  const jwtToken = sessionStorage.getItem("jwtToken");
  const childRef = useRef();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);

  const callChildFunction = () => {
    if (childRef.current) {
      childRef.current.childFunction(); // Calls the child function
    }
  };

  useEffect(() => {
    const fetchEmployeeLogoutData = async () => {
      try {
        const employeeResponse = await fetch(`/loginLogoutTime/${uniqueID}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        });
        const employee = await employeeResponse.json();

        if (Array.isArray(employee) && employee.length > 0) {
          const { id, ...empData } = employee[0];
          setId(id);
          setEmployeeData(empData);
        } else {
          setEmployeeData([]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchEmployeeLogoutData();
  }, [uniqueID]);

  useEffect(() => {
    const createTimeout1 = () =>
      setTimeout(() => {
        setWarningModalOpen(true);
        //   }, 300000);
      }, 30000000);

    const createTimeout2 = () =>
      setTimeout(() => {
        handleLogout();
      }, 15000);

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
        timeout = createTimeout1();
      }
    };

    let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1();

    addEventListeners(listener);

    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen]);

  const handleLogout = async () => {
    const logoutTime = new Date().toISOString();

    try {
      const response = await fetch(`/updateLogoutTime`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          uniqueID: uniqueID,
          logout: logoutTime,
        }),
      });

      if (response.status === 200) {
        sessionStorage.clear();

        // navigate("/", { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
    sessionStorage.clear();
  };

  useEffect(() => {
    callChildFunction();
  }, [isWarningModalOpen]);

  return (
    <div>
      {isWarningModalOpen && (
        <TimeOutPopUp
          ref={childRef}
          isOpen={isWarningModalOpen}
          onRequestClose={() => setWarningModalOpen(false)}
          callParent={() => {
            handleLogout();
          }}
        />
      )}
    </div>
  );
};
