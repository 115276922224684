import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import TimezoneSelect from "react-timezone-select";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import DatePicker from "react-datepicker";
import { FaPhoneAlt } from "react-icons/fa";
import { SiTicktick } from "react-icons/si";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { FaPeopleGroup } from "react-icons/fa6";
import { Toast } from "primereact/toast";
import { FaHeadset } from "react-icons/fa";
import { BsDatabaseFillDown } from "react-icons/bs";
import { FaTools } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import ThankYoU from "../../images/Thankyou.png";
import { Divider } from "primereact/divider";
import { IoMdTime } from "react-icons/io";
import { BiLogoMicrosoftTeams } from "react-icons/bi";

function Home() {
  const toast = useRef(null);
  const [fullName, setFullName] = useState("");
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [emailID, setEmailID] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedDateDdMMYyyy, setFormattedDateDdMMYyyy] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [timezoneInfo, setTimezoneInfo] = useState("");
  const [timeSlots, setTimeSlots] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const today = new Date();
  const [timezone, setTimezone] = useState(null);

  useEffect(() => {
    // Detect and set timezone automatically
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone({ value: detectedTimezone, label: detectedTimezone });
    setTimezoneInfo(detectedTimezone);
  }, []);
  const handleTimezoneChange = (selectedTimezone) => {
    setTimezone(selectedTimezone);
    setTimezoneInfo(selectedTimezone.label);
  };

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const fetchTimeSlots = async () => {
    try {
      const response = await fetch("/generalTimeSlots");
      if (!response.ok) {
        throw new Error("Failed to fetch time slots");
      }
      const data = await response.json();
      return data.slotTimes || [];
    } catch (error) {
      console.error("Error fetching time slots:", error);
      return [];
    }
  };

  const fetchScheduledCalls = async () => {
    try {
      const response = await fetch(
        `/scheduledCallsOnDate/${formattedDate}`,
        {}
      );
      const result = await response.json();
      return Array.isArray(result) ? result : [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const filterAvailableTimeSlots = (
    slotTimes,
    scheduledCalls,
    selectedDate
  ) => {
    const currentTime = new Date();
    const isToday =
      currentTime.toISOString().split("T")[0] ===
      selectedDate.toISOString().split("T")[0];

    let futureSlots;

    if (isToday) {
      // Filter out past time slots if the selected date is today
      const currentTimeString =
        currentTime.getHours().toString().padStart(2, "0") +
        ":" +
        currentTime.getMinutes().toString().padStart(2, "0");

      futureSlots = slotTimes.filter((time) => {
        const [hour, minute] = time.split(":").map((num) => parseInt(num, 10));
        const slotTime = new Date(currentTime);
        slotTime.setHours(hour, minute, 0, 0);
        return slotTime > currentTime;
      });
    } else {
      // For future dates, all slots are valid
      futureSlots = slotTimes;
    }

    const scheduledTimes = scheduledCalls.map((call) => call.callTime.trim());

    // Exclude already scheduled time slots
    const availableSlots = futureSlots.filter(
      (time) => !scheduledTimes.includes(time.trim())
    );

    return availableSlots;
  };

  // Fetch data and apply the filtering logic
  const fetchDataAndFilterSlots = async () => {
    const slotTimes = await fetchTimeSlots();
    const scheduledCalls = await fetchScheduledCalls();

    const availableSlots = filterAvailableTimeSlots(
      slotTimes,
      scheduledCalls,
      startDate
    );

    const formattedSlots = availableSlots.map((time) => ({
      label: time.slice(0, 5),
      value: time,
    }));

    setTimeSlots(formattedSlots);
  };
  useEffect(() => {
    fetchDataAndFilterSlots();
  }, [formattedDate]);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/aboutus");
  };

  const handleTimeSlotChange = (e) => {
    setSelectedTimeSlot(e.value);
  };

  const handleDateChange = (date) => {
    setStartDate(date);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const formattedDDMMYYY = `${day}-${month}-${year}`;
    setFormattedDateDdMMYyyy(formattedDDMMYYY);

    const formatted = `${year}-${month}-${day}`;
    setFormattedDate(formatted);
  };

  const handleSubmitApi = async (e) => {
    e.preventDefault();

    const dateAndTime = new Date(`${formattedDate}T${selectedTimeSlot}`);
    const formattedPhone = phone.startsWith("+") ? phone : `+${phone}`;

    try {
      const response = await fetch("/savescheduledCalls", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName: fullName,
          emailAddress: emailID,
          mobileNumber: formattedPhone,
          callDateTime: dateAndTime.toISOString(),
          timeZone: timezoneInfo,
          message: message,
          callCategory: "Free",
          amount: 0,
        }),
      });

      // Check the response status
      if (response.ok) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved",
          life: 10000,
        });

        setFullName("");
        setMessage("");
        setEmailID("");
        setPhone("");
        setFormattedDate("");
        setOpenSuccessDialog(true);
        setOpenConfirmDialog(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Some Error Occured",
          life: 5000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Some Error Occured",
        error,
        life: 5000,
      });
    }
  };

  const handleNavigate = () => {
    navigate("/ScheduleAFreeCall");
  };

  const handleDayClassName = (date) => {
    if (startDate && date.getTime() === startDate.getTime()) {
      return "selected-day";
    }
    return "";
  };

  return (
    <div className="homeMain displyToastTop">
      <Toast ref={toast} />
      <Dialog
        visible={openConfirmDialog}
        onHide={() => {
          setOpenConfirmDialog(false);
        }}
        style={{ width: "50vw" }}
        className="ConfirmDialog">
        <div className="grid">
          {/* <div className="col-12 lg:col-6">
            <div className="confirmImage">
              <img src={ThankYoU} width={100} className="mt-6 ml-2" />
            </div>
          </div> */}
          <div className="col-12 lg:col-12">
            <div className="confirmDialogForm">
              <h3 className="text-center">Confirm Your Details</h3>

              <table width="100%">
                <thead>
                  <tr>
                    <td>FullName</td>
                    <td style={{ color: "#888888" }}>: {fullName} </td>
                  </tr>
                  <tr>
                    <td>EmailID</td>
                    <td style={{ color: "#888888" }}>: {emailID}</td>
                  </tr>
                  <tr>
                    <td>MobileNumber</td>
                    <td style={{ color: "#888888" }}>: {phone}</td>
                  </tr>
                  <tr>
                    <td>Date & Time</td>
                    <td style={{ color: "#888888" }}>
                      : {formattedDateDdMMYyyy} at {selectedTimeSlot}
                    </td>
                  </tr>
                  <tr>
                    <td>TimeZone</td>
                    <td style={{ color: "#888888" }}>: {timezoneInfo}</td>
                  </tr>
                  <tr>
                    <td>Message</td>
                    <td style={{ color: "#888888" }}>: {message}</td>
                  </tr>
                </thead>
              </table>
              <div className="flex justify-content-between mt-3">
                <div>
                  <p
                    className=""
                    style={{
                      marginTop: "-1px",
                      marginLeft: "5px",
                    }}>
                    <IoMdTime /> 30 Minutes
                  </p>
                </div>

                <div className="ml-3">
                  <p
                    className=""
                    style={{
                      marginTop: "-1px",
                      marginLeft: "5px",
                    }}>
                    <BiLogoMicrosoftTeams />
                    <span className="ml-1">
                      Meeting details provided to above EmailID.
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex justify-content-center">
                <Button
                  rounded
                  label="Edit or Cancel"
                  className="w-4"
                  outlined
                  severity="warning"
                  onClick={() => {
                    setOpenConfirmDialog(false);
                  }}
                />
                <Button
                  rounded
                  label="Confirm"
                  severity="success"
                  className="w-4 ml-3"
                  onClick={handleSubmitApi}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={openSuccessDialog}
        onHide={() => {
          setOpenSuccessDialog(false);
        }}
        className="dbaAllDialogs">
        <div className="thankYouDialog">
          <img src={ThankYoU} alt="" />
          <div className="flex homeFlex justify-content-center ">
            <Button
              onClick={() => {
                navigate("/");
                setOpenSuccessDialog(false);
                setSelectedTimeSlot("");
                setFormattedDate("");
              }}
              className="w-full"
              rounded
              raised
              label="Close"></Button>
          </div>
        </div>
      </Dialog>
      <session className="grid m-4 shadow-3 homeFirstSection">
        <div className="col-12 lg:col-6">
          <div className="home-content-subsection">
            <div className="home-heading Text-Gradient">
              Transforming Data into Actionable
              <br />
              Insights with SQL Expertise
              {/* Your All-in-One <br /> HR Solution HelloEMS <br /> Your eBuddy. */}
            </div>
            <div className="home-para Text-Gradient">
              Reliable SQL Solutions, Trusted Data Results
            </div>
            <Button className="mt-3 Text-Gradient calllink" text>
              <FaPhoneAlt
                className="m-2"
                style={{ color: "green", fontSize: "1.5rem" }}
              />
              <a className="home-Call-links" href="tel:+91 8886591188">
                +91 8886591188,
              </a>
              <a className="home-Call-links ml-2" href="tel:+91 9490384177">
                +91 9490384177
              </a>
            </Button>
          </div>
        </div>
        <div className="col-12 lg:col-6">
          <Card className="contact-subsection-right shadow-5 p-3 m-3">
            <h4 className="text-center">Book Free 30 Minute Call</h4>
            <div className="homeForm">
              <div className="homeForm flex homeFlex  mt-3">
                <div className="input-field">
                  <label className="label-line">Full Name</label>
                  <InputText
                    keyfilter={/^[a-zA-Z ]*$/}
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    // pattern=".{3,50}"
                    placeholder="Enter Your Name"
                    className=""
                  />
                </div>
                <div className="input-field">
                  <label className="label-line">EmailID</label>
                  <InputText
                    keyfilter="email"
                    value={emailID}
                    onChange={(e) => setEmailID(e.target.value)}
                    // pattern=".{3,50}"
                    placeholder="Enter EmailID"
                    className=""
                  />
                </div>
              </div>
              <div className="flex homeFlex mt-3">
                <div className="input-field w-9">
                  {" "}
                  <label className="label-line">Mobile Number</label>
                  <PhoneInput
                    country={"eg"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                </div>
                <div className="input-field ml-3 inputMessageAndDate">
                  <label className="label-line">ShortMessage</label>
                  <InputText
                    keyfilter={/^[a-zA-Z ]*$/}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    // pattern=".{3,50}"
                    placeholder="Enter Message"
                    className=""
                  />
                </div>
              </div>
              <div className="flex homeFlex mt-3">
                <div
                  className="custom-datePicker datepickerTd"
                  id="AttendanceParent">
                  {" "}
                  <label className="label-line">Pick Date And Time</label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    inline
                    dateFormat="yyyy-MM-dd"
                    minDate={today}
                  />
                </div>
                <div className="ml-3 inputMessageAndDate mt-4 ">
                  <div className="mb-2">
                    <h2>{formattedDateDdMMYyyy}</h2>
                  </div>

                    <div className="mb-2 mt-2 w-full">
                      <label className="mb-3 " style={{ fontWeight: "800" }}>
                        Select Time Slot
                      </label>
                      <Dropdown
                        id="floatingSelectGrid"
                        value={selectedTimeSlot}
                        options={timeSlots}
                        onChange={handleTimeSlotChange}
                        placeholder="Select Time Slot"
                        style={{ float: "right" }}
                        filter
                        // showClear
                        checkmark={true}
                        highlightOnSelect={true}
                        className="w-full mb-4 mt-3"
                      />
                    </div>
                    <div className="mt-4 ">
                      <b>Time Zone</b>
                      <TimezoneSelect
                        value={timezone}
                        onChange={handleTimezoneChange}
                        placeholder="Select a Timezone"
                        className="mt-2 w-13rem"
                      />
                    </div>
                </div>
              </div>

              <div className="flex homeFlex justify-content-center m-2">
                <div className="">
                  {" "}
                  <Button
                    label="Book A Free Call"
                    className=""
                    disabled={
                      !fullName ||
                      !emailID ||
                      !phone ||
                      !message ||
                      !timezone ||
                      !formattedDate ||
                      !selectedTimeSlot
                    }
                    onClick={() => {
                      setOpenConfirmDialog(true);
                    }}></Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </session>
      <section className="m-4 shadow-3 homeFirstSection">
        <Card>
          <h1 className="text-center Text-Gradient">
            Emergencies Happen In SQL Server
          </h1>
          <div className="grid">
            <div className="col-12 lg:col-6 ">
              <div className="mt-5 pl-2 ml-4">
                <p className="mb-3">
                  Emergencies can disrupt critical business operations,
                  requiring immediate intervention to prevent data loss,
                  downtime, and revenue impact.
                </p>
                <p className="mt-3">
                  Our emergency support service is a rapid-response solution
                  designed to troubleshoot, diagnose, and resolve unexpected
                  issues affecting database functionality or performance.
                </p>
                <p className="mt-3">
                  This support is essential for recovering from unforeseen
                  database failures, such as crashes, corruption, or security
                  breaches.
                </p>
              </div>
            </div>
            <div className="col-12 lg:col-6">
              <div className="homeEmergencies">
                <ul className="emergenciesPoints">
                  <SiTicktick />
                  <li>SQL Server Outages </li>
                  <SiTicktick />
                  <li>Service Interruptions </li>
                  <SiTicktick />
                  <li>Major Performance slowdowns</li>
                  <SiTicktick />
                  <li>Data Loss </li>
                  <SiTicktick />
                  <li>Cryptoware Attacks</li>
                  <SiTicktick />
                  <li>Security breaches</li>
                  <SiTicktick />
                  <li>SQL Troubleshooting</li>
                  <SiTicktick />
                  <li>Point-In-Time restore issues</li>
                </ul>
              </div>
            </div>
          </div>
        </Card>
      </section>
      <Divider />
      <section>
        <Card className="m-4 OurSolutionsCard">
          <h1 className="text-center Text-Gradient">Our Solutions</h1>
          <div className="grid">
            <div className="col-12 lg:col-6">
              <Card className="ourSolutionCrd-1">
                <div className="homeFlex">
                  <div className="1">
                    <h2 className="Text-Gradient">SQL Server Consultant</h2>
                    <p>
                      Gain expert guidance tailored to your business needs with
                      our SQL Server consulting services. We analyze your
                      database infrastructure to provide optimized strategies,
                      ensuring your SQL Server runs at peak performance,
                      security, and scalabilit.
                    </p>
                  </div>
                  <div className="p-2">
                    <FaPeopleGroup
                      style={{ fontSize: "8rem", padding: "1rem" }}
                    />
                  </div>
                </div>
                <Button
                  rounded
                  outlined
                  raised
                  label="know More"
                  className="ml-4 mt-3 mb-1 mr-3"
                  onClick={handleClick}></Button>
              </Card>
            </div>
            <div className="col-12 lg:col-6">
              <Card className="ourSolutionCrd-2">
                <div className="homeFlex">
                  <div className="1">
                    <h2 className="Text-Gradient">
                      SQL Server Managed Services
                    </h2>
                    <p>
                      Our managed services keep your SQL Server environment
                      stable, secure, and efficient. We handle routine
                      maintenance, updates, and performance tuning, so you can
                      focus on your business while we ensure your database runs
                      smoothly.
                    </p>
                  </div>
                  <div className="p-2">
                    <FaHeadset style={{ fontSize: "8rem", padding: "1rem" }} />
                  </div>
                </div>
                <Button
                  rounded
                  outlined
                  raised
                  label="know More"
                  className="ml-4 mt-3 mb-1 mr-3"
                  onClick={handleClick}></Button>
              </Card>
            </div>
          </div>
          <div className="grid">
            <div className="col-12 lg:col-6">
              <Card className="ourSolutionCrd-1">
                <div className="homeFlexExpert">
                  <div className="1">
                    <h2 className="Text-Gradient">Expert DBA Support</h2>
                    <p>
                      Hire skilled DBAs to enhance SQL Server performance,
                      security, and scalability. With flexible support options,
                      we’re here to assist on your terms, whether you need
                      occasional guidance or full-scale management.
                    </p>
                  </div>
                  <div className="p-2">
                    <BsDatabaseFillDown
                      style={{ fontSize: "8rem", padding: "1rem" }}
                    />
                  </div>
                </div>
                <Button
                  rounded
                  outlined
                  raised
                  label="know More"
                  className="ml-5 mt-3 mb-3 mr-3"
                  onClick={handleClick}></Button>
              </Card>
            </div>
            <div className="col-12 lg:col-6">
              <Card className="ourSolutionCrd-1">
                <div className="homeFlex">
                  <div className="1">
                    <h2 className="Text-Gradient">Emergency SQL Support</h2>
                    <p>
                      When unexpected issues arise, our emergency support team
                      is available 24/7 to resolve SQL Server problems swiftly.
                      From database crashes to urgent troubleshooting, we’re
                      here to minimize downtime and keep your systems
                      operational.
                    </p>
                  </div>
                  <div className="p-2">
                    <FaTools style={{ fontSize: "8rem", padding: "1rem" }} />
                  </div>
                </div>
                <Button
                  rounded
                  outlined
                  raised
                  label="know More"
                  className="ml-4 mt-3 mb-1 mr-3"
                  onClick={handleClick}></Button>
              </Card>
            </div>
          </div>
        </Card>
      </section>
      <div className="m-4 homeFirstSection">
        <Panel
          header="What does an SQL Server DBA do? "
          toggleable
          className="mb-2"
          onToggle={() => handleToggle(0)}
          collapsed={activeIndex !== 0}>
          <p className="m-0">
            A DBA (Database Administrator) manages and maintains SQL Server
            databases, ensuring performance, security, and availability. They
            handle tasks like backups, updates, monitoring, and troubleshooting.
          </p>
        </Panel>
        <Panel
          header="Why is SQL Server database maintenance important?"
          toggleable
          className="mb-2"
          onToggle={() => handleToggle(1)}
          collapsed={activeIndex !== 1}>
          <p className="m-0">
            Regular maintenance ensures optimal performance, minimizes downtime,
            and safeguards data, reducing risks of data loss or corruption.
          </p>
        </Panel>
        <Panel
          header="Can a DBA help with SQL Server migrations?"
          toggleable
          className="mb-2"
          onToggle={() => handleToggle(2)}
          collapsed={activeIndex !== 2}>
          <p className="m-0">
            Yes, DBAs assist with migrating data to new SQL Server versions or
            cloud platforms, ensuring a seamless transition without data loss.
          </p>
        </Panel>
        <Panel
          header="Why choose managed SQL Server DBA services?"
          toggleable
          className="mb-2"
          onToggle={() => handleToggle(3)}
          collapsed={activeIndex !== 3}>
          <p className="m-0">
            Managed DBA services provide expert support, proactive maintenance,
            and 24/7 monitoring, reducing the burden on in-house IT teams.
          </p>
        </Panel>
        <Panel
          header="How can a DBA improve application performance?"
          toggleable
          className="mb-2"
          onToggle={() => handleToggle(4)}
          collapsed={activeIndex !== 4}>
          <p className="m-0">
            A DBA can enhance application performance by optimizing database
            queries, indexing strategies, and configurations. They also analyze
            bottlenecks and recommend changes to the application architecture
            when necessary.
          </p>
        </Panel>
        <Panel
          header="Can a DBA assist with database design?"
          toggleable
          className="mb-2"
          onToggle={() => handleToggle(5)}
          collapsed={activeIndex !== 5}>
          <p className="m-0">
            Yes, DBAs play a crucial role in database design, including schema
            creation, normalization, and ensuring scalability. Their expertise
            ensures that databases are efficient and meet business needs.
          </p>
        </Panel>
      </div>
    </div>
  );
}

export default Home;
