import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import "./Managecalls.css";
import AddClientImg from "../../../images/Add Client.png";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { TabPanel, TabView } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

function ManageCalls() {
  const toast = useRef();
  const [createTimeDialog, setCreateTimeDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewAllCalls, setViewAllCalls] = useState(true);
  const [viewRemainingCalls, setViewRemainingCalls] = useState(false);
  const [allRemainingCalls, setAllRemainingCalls] = useState([]);
  const [allCallDetails, setAllCallDetails] = useState([]);
  const [viewGeneralSlots, setViewGeneralSlots] = useState(true);
  const [viewCustomSlots, setViewCustomSlots] = useState(false);
  const [allGeneralSlots, setAllGeneralSlots] = useState([]);
  const [allCustomSlots, setAllCustomSlots] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [formDataTimeSlots, setFormDataTimeSlots] = useState({
    apiId: "",
    slotCategory: "",
    slotDate: "",
    slotTime: "",
    availability: "",
  });
  const handleInputChange = (name, value) => {
    setFormDataTimeSlots((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  async function fetchAllGeneralSlots() {
    const response = await fetch("/allGeneralTimeSlots", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllGeneralSlots(result);
    } else {
      setAllGeneralSlots([]);
    }
  }

  async function fetchAllCustomSlots() {
    const response = await fetch("/allCustomTimeSlots", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllCustomSlots(result);
    } else {
      setAllCustomSlots([]);
    }
  }

  async function fetchAllCallDetails() {
    const response = await fetch("/allCalls", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllCallDetails(result);
    } else {
      setAllCallDetails([]);
    }
  }

  async function fetchAllFreeCallDetails() {
    setAllRemainingCalls([]);
    const response = await fetch("/allFreeCalls", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllRemainingCalls(result);
    } else {
      setAllRemainingCalls([]);
    }
  }

  async function fetchStandardCallDetails() {
    setAllRemainingCalls([]);
    const response = await fetch("/allStandardCalls", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllRemainingCalls(result);
    } else {
      setAllRemainingCalls([]);
    }
  }

  async function fetchPremiumCallDetails() {
    const response = await fetch("/allPremiumCalls", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    });
    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      setAllRemainingCalls(result);
    } else {
      setAllRemainingCalls([]);
    }
  }

  useEffect(() => {
    fetchAllCallDetails();
    fetchAllGeneralSlots();
    fetchAllCustomSlots();
  }, []);

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);

    // Fetch data based on the button clicked
    switch (buttonName) {
      case "All Calls":
        setViewAllCalls(true);
        fetchAllCallDetails();
        setViewRemainingCalls(false);
        break;
      case "Free Calls":
        setViewAllCalls(false);
        setViewRemainingCalls(true);
        fetchAllFreeCallDetails();
        break;
      case "Standard Calls":
        setViewAllCalls(false);
        setViewRemainingCalls(true);
        fetchStandardCallDetails();
        break;
      case "Premium Calls":
        setViewAllCalls(false);
        setViewRemainingCalls(true);
        fetchPremiumCallDetails();
        break;
      default:
        break;
    }
  };

  const crearVariales = () => {
    setFormDataTimeSlots({
      apiId: "",
      slotCategory: "",
      slotDate: "",
      slotTime: "",
    });
  };
  const handleSubmitNewTimeSlot = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formattedSlotTime = formDataTimeSlots.slotTime.includes(":")
      ? formDataTimeSlots.slotTime + ":00"
      : formDataTimeSlots.slotTime;
    try {
      const response = await fetch("/InsertBulkTimeSlots", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([
          {
            slotCategory: formDataTimeSlots.slotCategory,
            slotDate: formDataTimeSlots.slotDate,
            slotTime: formattedSlotTime,
            availability: true,
          },
        ]),
      });
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Saved Successfully!",
          life: 3000,
        });
        crearVariales();
        setCreateTimeDialog(false);
        fetchAllGeneralSlots();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error Occured While Saving",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Some Error Occured",
        life: 3000,
      });
    }

    setIsLoading(false);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) =>
          options.editorCallback(e.target.value.substring(0, 150))
        }
      />
    );
  };

  const onRowUpdateTimeSlots = async (e) => {
    let _tasks = [...allGeneralSlots];
    let { newData, index } = e;

    _tasks[index] = newData;

    setAllGeneralSlots(_tasks);

    try {
      const response = await fetch("/bulkUpdateTimeSlots", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([newData]),
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Updated successfully!",
          life: 3000,
        });
        // triggerApiDataChange();
        fetchAllGeneralSlots();
        //  setError("");
      }

      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update data!",
          life: 3000,
        });
        // triggerApiDataChange();
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const onRowUpdateCustomTimeSlots = async (e) => {
    let _tasks = [...allCustomSlots];
    let { newData, index } = e;

    _tasks[index] = newData;

    setAllCustomSlots(_tasks);

    try {
      const response = await fetch("/bulkUpdateTimeSlots", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        },
        body: JSON.stringify([newData]),
      });

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Details Updated successfully!",
          life: 3000,
        });
        // triggerApiDataChange();
        fetchAllGeneralSlots();
        //  setError("");
      }

      if (response.status !== 200) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update data!",
          life: 3000,
        });
        // triggerApiDataChange();
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  return (
    <div>
      <Toast ref={toast} />
      <Card className="m-5">
        {/*Time Slots */}
        <Dialog
          visible={createTimeDialog}
          style={{ width: "50vw" }}
          onHide={() => {
            setCreateTimeDialog(false);
            crearVariales();
          }}
          className="dialogAddForms">
          <Card className="shadow-5 AddHolidayyform">
            <h3 className="text-center">Add Time Slot</h3>
            <div className="grid">
              <div className="col-12  lg:col-6">
                <div className="addClientLeftImg">
                  <img src={AddClientImg} alt="" width="100%" />
                </div>
              </div>
              <div className="col-12 lg:col-6">
                <form onSubmit={handleSubmitNewTimeSlot}>
                  <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="Holiday Name" className="font-bold block">
                      Category
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>
                    <Dropdown
                      value={formDataTimeSlots.slotCategory}
                      options={[
                        { label: "General Slots", value: "General Slots" },
                        { label: "Custom Slots", value: "Custom Slots" },
                      ]}
                      onChange={(e) => {
                        setFormDataTimeSlots({
                          ...formDataTimeSlots,
                          slotCategory: e.value,
                        });
                      }}
                      placeholder="Select Category"
                      className="w-full"
                      checkmark={true}
                      highlightOnSelect={true}
                    />
                  </div>
                  {formDataTimeSlots.slotCategory === "Custom Slots" && (
                    <div className="flex flex-wrap gap-3 p-fluid">
                      <label
                        htmlFor="buttondisplay"
                        className="font-bold block">
                        Date
                        <span className="starForMandatoryInInputFields">*</span>
                      </label>

                      <InputText
                        aria-describedby="username-help"
                        type="date"
                        value={formDataTimeSlots.slotDate}
                        // className="datemobileFile"
                        onChange={(e) => {
                          handleInputChange("slotDate", e.target.value);
                        }}
                      />
                    </div>
                  )}
                  <div className="flex flex-wrap gap-3 p-fluid">
                    <label htmlFor="buttondisplay" className="font-bold block">
                      Time
                      <span className="starForMandatoryInInputFields">*</span>
                    </label>

                    <InputText
                      aria-describedby="username-help"
                      type="time"
                      value={formDataTimeSlots.slotTime}
                      // className="datemobileFile"
                      onChange={(e) => {
                        handleInputChange("slotTime", e.target.value);
                      }}
                    />
                  </div>
                  <div className="flex justify-content-center mt-3">
                    <Button
                      type="submit"
                      label="Submit"
                      loading={isLoading}
                      disabled={
                        !formDataTimeSlots.slotCategory ||
                        !formDataTimeSlots.slotTime
                      }
                      rounded
                      raised
                      className="w-full roundad"
                    />
                  </div>
                </form>
              </div>
            </div>
          </Card>
        </Dialog>
        <TabView>
          <TabPanel header="Manage Calls">
            <div className="callsButtonContainer">
              <Button
                label="All Calls"
                rounded
                outlined
                severity="help"
                className={
                  selectedButton === "All Calls" ? "selected-button" : ""
                }
                onClick={() => handleButtonClick("All Calls")}
              />
              <Button
                label="Free Calls"
                rounded
                outlined
                severity="Info"
                className={
                  selectedButton === "Free Calls" ? "selected-button" : ""
                }
                onClick={() => handleButtonClick("Free Calls")}
              />
              <Button
                label="Standard Calls"
                rounded
                outlined
                severity="success"
                className={
                  selectedButton === "Standard Calls" ? "selected-button" : ""
                }
                onClick={() => handleButtonClick("Standard Calls")}
              />
              <Button
                label="Premium Calls"
                rounded
                outlined
                severity="warning"
                className={
                  selectedButton === "Premium Calls" ? "selected-button" : ""
                }
                onClick={() => handleButtonClick("Premium Calls")}
              />
            </div>
            <Divider />
            {viewAllCalls && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allCallDetails}
                size="small"
                paginator
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found.">
                <Column
                  header="ClientName"
                  field="fullName"
                  frozen
                  filter
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="EmailID"
                  field="emailAddress"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="ContactNo"
                  field="mobileNumber"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="Category"
                  field="callCategory"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="CallDateTime"
                  field="CallDateTime"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                />
                <Column
                  header="TimeZone"
                  field="timeZone"
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="Amount"
                  field="amount"
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="Message"
                  field="message"
                  className="allTableTDCenterCommon"
                />
              </DataTable>
            )}
            {viewRemainingCalls && (
              <>
                <DataTable
                  tableStyle={{ minWidth: "50rem" }}
                  value={allRemainingCalls}
                  size="small"
                  paginator
                  rows={10}
                  showGridlines
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  removableSort
                  scrollable
                  scrollHeight="250px"
                  className="FilterTable allDataTableThCenterCommon m-2"
                  emptyMessage="No Data found.">
                  <Column
                    header="ClientName"
                    field="fullName"
                    frozen
                    filter
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="EmailID"
                    field="emailAddress"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                  <Column
                    header="ContactNo"
                    field="mobileNumber"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                  <Column
                    header="Category"
                    field="callCategory"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                  <Column
                    header="CallDateTime"
                    field="CallDateTime"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                  <Column
                    header="TimeZone"
                    field="timeZone"
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="Amount"
                    field="amount"
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="Message"
                    field="message"
                    className="allTableTDCenterCommon"
                  />
                </DataTable>
              </>
            )}
          </TabPanel>
          <TabPanel header="TimeSlots">
            <div className="callsButtonContainer flex justify-content-between">
              <div>
                <Button
                  label="Genaral TimeSlots"
                  rounded
                  onClick={() => {
                    setViewAllCalls(true);
                    setAllRemainingCalls(false);
                    setViewGeneralSlots(true);
                    setViewCustomSlots(false);
                    fetchAllGeneralSlots();
                  }}
                />
                <Button
                  label="Custom Timeslots"
                  rounded
                  onClick={() => {
                    setViewAllCalls(true);
                    setAllRemainingCalls(false);
                    setViewGeneralSlots(false);
                    setViewCustomSlots(true);
                    fetchAllCustomSlots();
                  }}
                />
              </div>
              <div>
                <Button
                  label="Add"
                  className="w-10rem"
                  rounded
                  onClick={() => {
                    setCreateTimeDialog(true);
                  }}
                />
              </div>
            </div>
            <Divider />
            {viewGeneralSlots && (
              <DataTable
                tableStyle={{ minWidth: "50rem" }}
                value={allGeneralSlots}
                size="small"
                paginator
                rows={10}
                showGridlines
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                removableSort
                scrollable
                scrollHeight="250px"
                className="FilterTable allDataTableThCenterCommon m-2"
                emptyMessage="No Data found."
                editMode="row"
                dataKey="id"
                onRowEditComplete={onRowUpdateTimeSlots}>
                <Column
                  header="ID"
                  field="id"
                  frozen
                  filter
                  className="allTableTDCenterCommon"
                />
                <Column
                  header="Category"
                  field="slotCategory"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditor(options)}
                />
                <Column
                  header="Time"
                  field="slotTime"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditor(options)}
                />
                <Column
                  header="Availability"
                  field="availability"
                  className="allTableTDCenterCommon"
                  sortable
                  filter
                  editor={(options) => textEditor(options)}
                />
                <Column rowEditor></Column>
              </DataTable>
            )}
            {viewCustomSlots && (
              <>
                <DataTable
                  tableStyle={{ minWidth: "50rem" }}
                  value={allCustomSlots}
                  size="small"
                  paginator
                  rows={10}
                  showGridlines
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  removableSort
                  scrollable
                  scrollHeight="350px"
                  className="FilterTable allDataTableThCenterCommon m-2"
                  emptyMessage="No Data found."
                  editMode="row"
                  dataKey="id"
                  onRowEditComplete={onRowUpdateCustomTimeSlots}>
                  <Column
                    header="ID"
                    field="id"
                    frozen
                    filter
                    className="allTableTDCenterCommon"
                  />
                  <Column
                    header="Category"
                    field="slotCategory"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                    editor={(options) => textEditor(options)}
                  />
                  <Column
                    header="Date"
                    field="SlotDate"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                  />
                  <Column
                    header="Time"
                    field="slotTime"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                    editor={(options) => textEditor(options)}
                  />
                  <Column
                    header="Availability"
                    field="availability"
                    className="allTableTDCenterCommon"
                    sortable
                    filter
                    editor={(options) => textEditor(options)}
                  />
                  <Column rowEditor></Column>
                </DataTable>
              </>
            )}
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}

export default ManageCalls;
